import React, { PropsWithChildren } from "react";

interface IProps {
    startQuiz: () => void;
}

const QuizIntroDecorated = (props: PropsWithChildren<IProps>) => {
    const { startQuiz } = props;

    return (
        <div>
                <div className="start-text step">Step 2:</div>
                <div className="start-text title">Find your super power!</div>
            <div className={"start-button"} onClick={startQuiz}>
                Start
            </div>
        </div>
    );
};

export const QuizIntro = QuizIntroDecorated;
