import { ReduxActionType } from "../../../common/types/ReduxActionType";
import { UpdateTaskAction } from "../actions/UpdateTaskAction";

export const createUpdateTaskAction = (taskID: number, task: string, complete: boolean): UpdateTaskAction => {
    return {
        type: ReduxActionType.UpdateTask,
        taskID,
        task,
        complete,
    };
};
