import React, { PropsWithChildren } from "react";
import { IWithThumbnailFile } from "../../../interfaces/quizzes/IFile";
import { IQuizAnswer } from "../../../interfaces/quizzes/IQuizAnswer";
import { StandardImageBox } from "../../Popups/StandardImageBox";

interface IProps {
    quizAnswer: IQuizAnswer & IWithThumbnailFile;
    onAnswerSelected: () => void;
    highlight?: boolean;
}

export const QuizAnswer = (props: PropsWithChildren<IProps>) => {
    const { quizAnswer, onAnswerSelected, highlight } = props;
    const { label, thumbnailFile } = quizAnswer;

    const classNames = ["quiz-answer", highlight ? "highlighted" : ""];

    return (
        <div className={classNames.join(" ")} onClick={onAnswerSelected}>
            {thumbnailFile ? <StandardImageBox file={thumbnailFile} classNames={["answer-image"]} backgroundSize={"cover"} /> : undefined}
            <div className={"answer-text noselect"}>
                <span>{label}</span>
            </div>
        </div>
    );
};
