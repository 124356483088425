import { actionReducer } from "../actionReducer";
import { isAddTaskAction } from "../actionTests/isAddActionTask";
import { initialState } from "../initialState";
import { analytics, analyticsCategories } from "../../consts/Analytics";

export const addTaskReducer = actionReducer(
    initialState,
    (previousState = initialState, action) => {
        analytics.track("addTask", {
            category: analyticsCategories.checklist,
            label: previousState.checklist.length + 1,
        });
        return {
            ...previousState,
            checklist: [...previousState.checklist, { task: action.task }],
        };
    },
    isAddTaskAction,
);
