import React, { PropsWithChildren } from "react";
import { buildOptionalFileBackgroundCSS } from "../../utils/buildOptionalFileBackgroundCSS";

interface IProps {
    file: string;
    classNames?: string[];
    backgroundSize?: string;
    showCredit?: boolean;
}

export const StandardImageBox = (props: PropsWithChildren<IProps>) => {
    const { file, classNames: propertyClassNames, backgroundSize: propertyBackgroundSize, showCredit, children } = props;

    const classNames = [...(propertyClassNames || []), "standard-image", "noselect"];
    const backgroundSize = propertyBackgroundSize || "cover";
    const credit = "//Todo: Image credit";
    const style = buildOptionalFileBackgroundCSS(file, backgroundSize);

    //console.log("style", style);

    return (
        <div className={classNames.join(" ")} style={style}>
            <div className={"imageCredit"}>{showCredit ? credit : null}</div>
            {children}
        </div>
    );
};
