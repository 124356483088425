import { actionReducer } from "../actionReducer";
import { isSetCustomChecklist } from "../actionTests/isSetCustomChecklistAction";
import { initialState } from "../initialState";
import { analytics, analyticsCategories } from "../../consts/Analytics";

export const setCustomChecklistReducer = actionReducer(
    initialState,
    (previousState = initialState, action) => {
        analytics.track("setCustomChecklist", {
            category: analyticsCategories.checklist,
            label: action.customChecklist,
        });

        let checklist: { task: string; complete?: boolean }[] = [];

        if (!action.customChecklist) {
            checklist = initialState.checklist;
        }

        return {
            ...previousState,
            checklist,
            customChecklist: action.customChecklist,
        };
    },
    isSetCustomChecklist,
);
