import React, { PropsWithChildren } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { withConfig, WithConfig } from "../hocs/withConfig";

interface IMetaAsName {
    name: string;
    content?: string;
}

interface IMetaAsProperty {
    property: string;
    content?: string;
}

type Meta = (IMetaAsName | IMetaAsProperty)[];

interface IProps {
    description?: string;
    lang?: string;
    meta?: Meta;
    title: string;
}

const defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

const SEODecorated = (props: PropsWithChildren<IProps & WithConfig>) => {
    const { description, lang, meta, title, config } = { ...defaultProps, ...props };
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `,
    );

    const metaDescription = `${description || site.siteMetadata.description}`;

    const deriveSiteUrl = () => {
        // Prefer config
        if (config.netlifyUrl !== "" && config.netlifyUrl !== "undefined") {
            return config.netlifyUrl;
        }

        // If the window object is present
        if (typeof window !== "undefined") {
            return `${window.location.protocol}//${window.location.host}`;
        }

        // No can do
        return "";
    };

    const siteUrl = deriveSiteUrl();

    const fullMeta: Meta = [
        ...meta,
        {
            name: `description`,
            content: metaDescription,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:creator`,
            content: `${site.siteMetadata.author}`,
        },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:description`,
            content: metaDescription,
        },
        {
            name: `og:image`,
            content: `${siteUrl}${require("../favicon.png")}`,
        },
    ];

    const metaChildren = fullMeta.map((item, key) => {
        return <meta {...item} key={key} />;
    });

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={fullMeta}
        >
            {metaChildren}
            <meta charSet="utf-8" />
            <link href="https://fonts.googleapis.com/css2?family=Fredoka+One&family=Open+Sans&family=Luckiest+Guy&display=swap" rel="stylesheet" />
        </Helmet>
    );
};

export const SEO = withConfig(SEODecorated);
