import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import { ActivityCodes } from "./ActivityCodes";

export const analytics = Analytics({
    app: "how-to-hero-site",
    plugins: [
        googleAnalytics({
            trackingId: `${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`,
        }),
    ],
});

export const analyticsCategories = {
    ...ActivityCodes,
    debug: "debug",
};
