import { actionReducer } from "../actionReducer";
import { isIncrementCounterAction } from "../actionTests/isIncrementCounterAction";
import { initialState } from "../initialState";

export const incrementCounterReducer = actionReducer(
    initialState,
    (previousState = initialState) => {
        return {
            ...previousState,
            counter: previousState.counter + 1,
        };
    },
    isIncrementCounterAction,
);
