export enum ReduxActionType {
    SetCounter,
    IncrementCounter,
    SetSuperheroName,
    SetLogoDrawing,
    AddTask,
    RemoveTask,
    UpdateTask,
    SetCustomChecklist,
    SetCostumeSelection,
    SetActivityCompletion,
    ResetAll,
    SetQuizResult,
    SetRescueRecord,
    SetLogoBackground,
}
