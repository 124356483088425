import React, { PropsWithChildren, useState } from "react";
import { WithActions, withActions } from "../../../hocs/withActions";
import { WithState, withState } from "../../../hocs/withState";
import { PrefixList, NonAnimalNounList as NounList } from "../../../consts/HeroNames";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { analyticsCategories } from "../../../consts/Analytics";
import { ActivityCodes } from "../../../consts/ActivityCodes";
import { IActivityProps } from "../../../interfaces/activityProps";
import { GetNextActivityIndex } from "../../../consts/ActivityInfo";

const waitForSeconds = (seconds: number) => {
    return new Promise<void>((resolve) => {
        window.setTimeout(resolve, seconds * 1000);
    });
};

const SuperNameGeneratorDecorated = (props: PropsWithChildren<IActivityProps & WithState & WithActions & WithAnalytics>) => {
    const {
        analytics,
        state: { superheroName, completedActivities },
        selectActivity,
        actions: { setSuperheroName, setActivityCompletion },
    } = props;
    const currentActivity = props.currentActivity ? props.currentActivity : ActivityCodes.nameGen;
    const isComplete = new Map<string, boolean>(completedActivities).get(currentActivity);
    const [isGenerating, setIsGenerating] = useState(false);
    const [generatedName, setGeneratedName] = useState("generating");
    const [showIntro, setShowIntro] = useState<boolean>(true);

    const pickRandomName = () => {
        let newName = superheroName;
        let loopCount = 0;

        //Keep generating names until we get a different one.
        //Even with a tiny list size there's almost no chance this takes more than 5 rolls.
        while (newName == superheroName && loopCount < 5) {
            const prefixIndex = Math.floor(Math.random() * PrefixList.length);
            const nounIndex = Math.floor(Math.random() * NounList.length);
            newName = PrefixList[prefixIndex] + NounList[nounIndex];
            ++loopCount; //Emergency exit if something's gone wrong.
        }
        return newName;
    };
    const generateGarbageName = async (howMany = 10, delay = 0.05) => {
        setIsGenerating(true);
        for (let i = 0; i < howMany; i++) {
            setGeneratedName(pickRandomName());
            await waitForSeconds(delay);
        }
        setIsGenerating(false);
    };

    const generateRandomName = async () => {
        const newName = pickRandomName();
        await generateGarbageName();
        setSuperheroName(newName);

        analytics.track("nameGenerated", {
            category: analyticsCategories.nameGen,
            label: newName,
        });

        setActivityCompletion(currentActivity, true);
    };

    const nextActivity = () => {
        if (selectActivity) {
            selectActivity(GetNextActivityIndex(currentActivity));
        }
    };

    const enableGenerator = () => {
        setShowIntro(false);
    };

    return (
        <>
            {showIntro && !isComplete ? (
                <div className={"name-intro"}>
                    <div className="start-text step">Step 1:</div>
                    <div className="start-text title">Choose your superhero name!</div>
                    <p>Tap the dice to create a new identity!</p>
                    <div className={"start-button name-start"} onClick={enableGenerator}>
                        Start
                    </div>
                </div>
            ) : (
                <>
                    <div className={"generator-text"}>My superhero name is...</div>
                    <div className="generator-name-button-row">
                        <div className={"generator-name"}>{isGenerating ? generatedName : superheroName}</div>
                        <div className={"randomise generator-button"} onClick={generateRandomName} />
                    </div>
                    {isComplete ? <div className={"generator-continue continue image"} onClick={nextActivity} /> : null}
                </>
            )}
        </>
    );
};

export const SuperNameGenerator = withState(withActions(withAnalytics(SuperNameGeneratorDecorated)));
