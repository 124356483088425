import React, { PropsWithChildren, useState } from "react";
import { QuizType } from "../../../consts/QuizType";
import { IWithThumbnailFile } from "../../../interfaces/quizzes/IFile";
import { IQuizAnswer } from "../../../interfaces/quizzes/IQuizAnswer";
import { PopulatedQuiz } from "../../../interfaces/quizzes/PopulatedQuiz";
import { PopulatedQuizQuestion } from "../../../interfaces/quizzes/PopulatedQuizQuestion";
import { QuizAnswer } from "./QuizAnswer";
import { QuizQuestionImage } from "./QuizQuestionImage";

interface IProps {
    quiz: PopulatedQuiz;
    currentQuizQuestion: PopulatedQuizQuestion;
    quizQuestion: PopulatedQuizQuestion;
    onAnswerSelected: (quizQuestion: PopulatedQuizQuestion, quizAnswer: IQuizAnswer) => void;
}

const waitFor = (milliseconds: number): Promise<void> => {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, milliseconds);
    });
};

const getCorrectAnswer = (quiz: PopulatedQuiz, quizQuestion: PopulatedQuizQuestion) => {
    // No right answer
    if (quiz.quizType === QuizType.Personality) {
        return undefined;
    }

    // Sort answers by score, and return the best
    return [...quizQuestion.quizAnswers]
        .sort((a, b) => {
            return parseInt(`${a.score || 0}`, 10) - parseInt(`${b.score || 0}`, 10);
        })
        .pop();
};

export const QuizQuestion = (props: PropsWithChildren<IProps>) => {
    const { quiz, quizQuestion, currentQuizQuestion, onAnswerSelected } = props;

    const quizQuestionIndex = quiz.quizQuestions.indexOf(quizQuestion);
    const currentQuizQuestionIndex = quiz.quizQuestions.indexOf(currentQuizQuestion);
    const correctAnswer = getCorrectAnswer(quiz, quizQuestion);

    const [revealed, setRevealed] = useState(quiz.quizType !== QuizType.ChallengeReveal);
    const [highlightAnswer, setHighlightAnswer] = useState(false);

    // Choose which class to render the parent with
    const parentClass = ["quiz-question", revealed ? "revealed" : ""];

    if (quizQuestionIndex < currentQuizQuestionIndex) {
        parentClass.push("left");
    } else if (quizQuestionIndex > currentQuizQuestionIndex) {
        parentClass.push("right");
    }

    const onAnswerSelectedFactory = (quizAnswer: IQuizAnswer & IWithThumbnailFile) => {
        return async () => {
            if (quiz.quizType === QuizType.ChallengeReveal) {
                setRevealed(true);
                setHighlightAnswer(true);
                await waitFor(2000);
                onAnswerSelected(quizQuestion, quizAnswer);
            } else {
                onAnswerSelected(quizQuestion, quizAnswer);
            }
        };
    };

    const showQuestionImage = quiz.quizType === QuizType.ChallengeReveal || quiz.quizType === QuizType.Slider;

    return (
        <div className={parentClass.join(" ")} key={quizQuestion.id}>
            {showQuestionImage ? <QuizQuestionImage quizQuestion={quizQuestion} revealed={revealed} /> : null}
            <div className={"quiz-answers"}>
                {quizQuestion.quizAnswers.map((quizAnswer, index) => {
                    return <QuizAnswer onAnswerSelected={onAnswerSelectedFactory(quizAnswer)} key={index} quizAnswer={quizAnswer} highlight={highlightAnswer && quizAnswer === correctAnswer} />;
                })}
            </div>
        </div>
    );
};
