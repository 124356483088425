export const ChecklistOptions = [
    "Plant a tree",
    "Create a compost bin",
    "Donate to charity",
    "Write thank you notes",
    "Find 5 items to recycle",
    "Create art for others",
    "Use less plastic",
    "Wear sun screen",
    "Build a veggie garden",
    "Build a bee hive",
    "Share with others",
    "Listen to an old story",
];
