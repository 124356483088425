import React, { PropsWithChildren } from "react";
import { IWithThumbnailFile } from "../../../interfaces/quizzes/IFile";
import { IQuizQuestion } from "../../../interfaces/quizzes/IQuizQuestion";
import { StandardImageBox } from "../../Popups/StandardImageBox";

interface IProps {
    quizQuestion: IQuizQuestion & IWithThumbnailFile;
    revealed: boolean;
}

export const QuizQuestionImage = (props: PropsWithChildren<IProps>) => {
    const { quizQuestion, revealed } = props;

    const { thumbnailFile } = quizQuestion;

    if (thumbnailFile) {
        const cover = (
            <div className={"cover"}>
                <span>?</span>
            </div>
        );
        return (
            <StandardImageBox file={thumbnailFile} classNames={["quiz-question-image"]} backgroundSize={"contain"}>
                {revealed ? undefined : cover}
            </StandardImageBox>
        );
    } else {
        return null;
    }
};
