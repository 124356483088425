import React, { PropsWithChildren } from "react";
import { IWithThumbnailFile } from "../../../interfaces/quizzes/IFile";
import { IQuizResult } from "../../../interfaces/quizzes/IQuizResult";
import { StandardImageBox } from "../../Popups/StandardImageBox";
import { IMinimumPopupProps } from "../../Popups/Popup";

export interface IProps extends IMinimumPopupProps {
    quizResult: IQuizResult & IWithThumbnailFile;
}

export const QuizResult = (props: PropsWithChildren<IProps>) => {
    const { quizResult } = props;

    const { thumbnailFile } = quizResult;

    return (
        <div className={"quiz-result"}>
            <div className="activity-header">My Super Power is: </div>
            {thumbnailFile ? <StandardImageBox file={thumbnailFile} backgroundSize={"cover"} classNames={["result-image"]} /> : undefined}
            <div className={"result-text noselect"}>
                <div className={"result-label"}>
                    <h1>{quizResult.label}</h1>
                </div>
                <div className={"result-detail"}>{quizResult.detail}</div>
            </div>
        </div>
    );
};
