import { ReduxActionType } from "../../../common/types/ReduxActionType";
import { SetCostumeSelectionAction } from "../actions/SetCostumeSelectionAction";

export const createSetCostumeSelection = (selectorIndex: number, partIndex: number): SetCostumeSelectionAction => {
    return {
        type: ReduxActionType.SetCostumeSelection,
        selectorIndex,
        partIndex,
    };
};
