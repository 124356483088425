import React, { PropsWithChildren } from "react";
import { IActivityProps } from "../../../interfaces/activityProps";

const IntroDecorated = (props: PropsWithChildren<IActivityProps>) => {
    const NextPage = () => {
        props.selectActivity ? props.selectActivity(1) : null; //Go to the first activity
    };

    return (
        <>
            <div className="cover">
                <div className="cover-art">How to Hero - Step By Step Instructions for Brave Kids</div>
                <a className="start-book" onClick={NextPage}>
                    Become a hero now!
                </a>
            </div>
        </>
    );
};

export const Intro = IntroDecorated;
