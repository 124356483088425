import React, { PropsWithChildren } from "react";

interface IProps {
    onYes: () => void;
    onNo: () => void;
    show: boolean;
}

const ConfirmationPopupDecorated = (props: PropsWithChildren<IProps>) => {
    const { children, onYes, onNo, show } = props;

    return (
        <>
            {show ? (
                <div className={"confirm-reset popupBackground"}>
                    <div className="popup">
                        <div className="text">{children}</div>
                        <div className="buttons">
                            <div className={"confirm-reset-yes button yes"} onClick={onYes}>
                                YES
                            </div>
                            <div className={"confirm-reset-no button no"} onClick={onNo}>
                                NO
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export const ConfirmationPopup = ConfirmationPopupDecorated;
