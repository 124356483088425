//ALL PREFIXES SHOULD END WITH A SPACE (or other appropriate punctuation).
export const PrefixList: string[] = [
    "The Silly ",
    "The Incredible ",
    "Super-",
    "Combat ",
    "Unknowable ",
    "The Flamin' ",
    "The Gallant ",
    "Extravagant ",
    "Flightless ",
    "Laser ",
    "Turbo-",
    "Octo-",
    "The Significant ",
    "Frightening ",
    "The Mysterious ",
    "Spider-",
    "The Hidden ",
    "The Forbidden ",
    "Overbearing ",
    "The Divine ",
    "The Mythical ",
    "Rampant ",
    "The Ultimate ",
    "Invisible ",
    "The Slick ",
];

export const NonAnimalNounList: string[] = [

    // Animals we have heads for
    "Wombat",
    "Sugar-Glider",
    "Racoon",
    "Dropbear",
    "Emu",

    // Other names
    "Do-Gooder",
    "Bandit",
    "Ranger",
    "Runner",
    "Sprinter",
    "Dancer",
    "Flier",
    "Soarer",
    "Swimmer",
    "Floater",
    "Hopper",
    "Jumper",
    "Kicker",
    "Leaper",
    "Singer",
    "Crawler",
    "Hero",
    "Champion",
    "Champ",
    "Legend",
    "Saviour",
    "Helper",
    "Samaritan",
    "Kid",
    "Junior",
    "Pipsqueak",
];

export const NounList: string[] = [
    "Goose",
    "Duck",
    "Wombat",
    "Galah",
    "Kangaroo",
    "Glider",
    "Emu",
    "Feather",
    "Magpie",
    "Possum",
    "Lizard",
    "Turtle",
    "Owl",
    "Frill-Neck",
    "Spider",
    "Frog",
    "Toad",
    "Reptile",
    "Dropbear",
    "Koala",
    "Dingo",
    "Iguana",
    "Ram",
    "Echidna",
    "Fish",
    "Jaguar",
    "Deer",
    "Platypus",
    "Snake",
    "Llama",
    "Sloth",
    "Yak"
];
