import reduceReducers from "reduce-reducers";
import { Reducer } from "redux";
import { State } from "../../common/types/State";
import { Action } from "./Action";
import { initialState } from "./initialState";
import { incrementCounterReducer } from "./reducers/incrementCounterReducer";
import { setCounterReducer } from "./reducers/setCounterReducer";
import { setSuperheroNameReducer } from "./reducers/setSuperheroNameReducer";
import { setLogoDrawingReducer } from "./reducers/setLogoDrawingReducer";
import { addTaskReducer } from "./reducers/addTaskReducer";
import { updateTaskReducer } from "./reducers/updateTaskReducer";
import { removeTaskReducer } from "./reducers/removeTaskReducer";
import { setCustomChecklistReducer } from "./reducers/setCustomChecklistReducer";
import { setCostumeSelectionReducer } from "./reducers/setCostumeSelectionReducer";
import { setActivityCompletionReducer } from "./reducers/setActivityCompletionReducer";
import { resetAllReducer } from "./reducers/resetAllReducer";
import { setQuizResultReducer } from "./reducers/setQuizResultReducer";
import { setRescueRecordReducer } from "./reducers/setRescueRecordReducer";
import { setLogoBackgroundReducer } from "./reducers/setLogoBackgroundReducer";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reducer: Reducer<State, Action> = reduceReducers<State>(
    initialState,
    setCounterReducer,
    incrementCounterReducer,
    setSuperheroNameReducer,
    addTaskReducer,
    updateTaskReducer,
    removeTaskReducer,
    setCustomChecklistReducer,
    setCostumeSelectionReducer,
    setActivityCompletionReducer,
    setQuizResultReducer,
    setLogoDrawingReducer,
    setLogoBackgroundReducer,
    setRescueRecordReducer,
    resetAllReducer,
) as any;
