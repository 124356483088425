import React, { PropsWithChildren, useState } from "react";
import { WithActions, withActions } from "../../../hocs/withActions";
import { WithState, withState } from "../../../hocs/withState";
import { PartSelector } from "./PartSelector";
import { CostumeParts } from "../../../consts/CostumeParts";
import { WithAnalytics, withAnalytics } from "../../../hocs/withAnalytics";
import { analyticsCategories } from "../../../consts/Analytics";
import { ActivityCodes } from "../../../consts/ActivityCodes";
import { GetNextActivityIndex } from "../../../consts/ActivityInfo";
import { IActivityProps } from "../../../interfaces/activityProps";

const CostumePickerDecorated = (props: PropsWithChildren<IActivityProps & WithActions & WithState & WithAnalytics>) => {
    const { analytics } = props;
    const { costumeSelections } = props.state;
    const { setCostumeSelection } = props.actions;
    const currentActivity = props.currentActivity ? props.currentActivity : ActivityCodes.costume;
    const canEdit = !new Map<string, boolean>(props.state.completedActivities).get(currentActivity);
    const [showIntro, setShowIntro] = useState<boolean>(true);

    const RandomiseSelections = () => {
        analytics.track("randomiseCostume", {
            category: analyticsCategories.costume,
            label: "randomise",
        });

        CostumeParts.map((entry, index) => {
            //This returns a random number from 1 to (length - 1), to prevent duplicates.
            const shift = Math.floor(Math.random() * (entry.length - 1)) + 1;
            //Shift the currently selected index by shift, account for looping.
            const newPart = (costumeSelections[index] + shift) % entry.length;
            setCostumeSelection(index, newPart);
        });
    };

    const OnDone = () => {
        props.actions.setActivityCompletion(currentActivity, true);
    };

    const EnableEditing = () => {
        props.actions.setActivityCompletion(currentActivity, false);
        setShowIntro(false);
    };

    const NextActivity = () => {
        if (props.selectActivity) {
            props.selectActivity(GetNextActivityIndex(currentActivity));
        }
    };

    return (
        <>
            {showIntro && canEdit ? (
                <div className={"costume-intro"}>
                    <div className={"costume-intro-text"}>
                        <div className="start-text step">Step 3:</div>
                        <div className="start-text title">Create your costume</div>
                    </div>
                    <div className={"start-button costume-start"} onClick={EnableEditing}>
                        Start
                    </div>
                </div>
            ) : (
                <>
                    {!canEdit ? <div className={"activity-header"}>My costume is:</div> : null}
                    <div className={"costume-selector-wrapper"}>
                        {costumeSelections.map((entry, index) => {
                            return <PartSelector key={index} selectorID={index} partIndex={entry} parts={CostumeParts[index]} canEdit={canEdit} />;
                        })}
                    </div>
                    <div className="costume-buttons">
                        {canEdit ? <div className="costume-randomise randomise" onClick={RandomiseSelections} /> : null}
                        {canEdit ? (
                            <div className="costume-done" onClick={OnDone}>
                                Done
                            </div>
                        ) : (
                            <div className="costume-edit" onClick={EnableEditing}>
                                Change
                            </div>
                        )}
                        {canEdit ? null : <div className={"continue image"} onClick={NextActivity} />}
                    </div>
                </>
            )}
        </>
    );
};

export const CostumePicker = withActions(withState(withAnalytics(CostumePickerDecorated)));
