import { ComponentType } from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { createIncrementCounterAction } from "../redux/actionCreators/createIncrementCounterAction";
import { createSetCounterAction } from "../redux/actionCreators/createSetCounterAction";
import { createSetSuperheroNameAction } from "../redux/actionCreators/createSetSuperheroNameAction";
import { createSetLogoDrawingAction } from "../redux/actionCreators/createSetLogoDrawingAction";
import { createAddTaskAction } from "../redux/actionCreators/createAddTaskAction";
import { createRemoveTaskAction } from "../redux/actionCreators/createRemoveTaskAction";
import { createUpdateTaskAction } from "../redux/actionCreators/createUpdateTaskAction";
import { createSetCustomChecklist } from "../redux/actionCreators/createSetCustomChecklistAction";
import { createSetCostumeSelection } from "../redux/actionCreators/createSetCostumeSelectionAction";
import { createSetActivityCompletion } from "../redux/actionCreators/createSetActivityCompletionAction";
import { createResetAll } from "../redux/actionCreators/createResetAllAction";
import { createSetQuizResultAction } from "../redux/actionCreators/createSetQuizResultAction";
import { createSetRescueRecordAction } from "../redux/actionCreators/createSetRescueRecordAction";
import { createSetLogoBackgroundAction } from "../redux/actionCreators/createSetLogoBackgroundAction";

export type WithActions = {
    actions: {
        setCounter: typeof createSetCounterAction;
        incrementCounter: typeof createIncrementCounterAction;
        setSuperheroName: typeof createSetSuperheroNameAction;
		setLogoDrawing: typeof createSetLogoDrawingAction;
        setLogoBackground: typeof createSetLogoBackgroundAction;
        tasks: {
            addTask: typeof createAddTaskAction;
            removeTask: typeof createRemoveTaskAction;
            updateTask: typeof createUpdateTaskAction;
            setCustomChecklist: typeof createSetCustomChecklist;
        };
        setCostumeSelection: typeof createSetCostumeSelection;
        setActivityCompletion: typeof createSetActivityCompletion;
        resetAll: typeof createResetAll;
        setQuizResult: typeof createSetQuizResultAction;
        setRescueRecord: typeof createSetRescueRecordAction;
    };
};

const wrapDispatch = <T extends Action, U extends unknown[]>(dispatch: Dispatch<T>, fn: (...args: U) => T) => {
    return (...args: U) => {
        return dispatch(fn(...args));
    };
};

export const withActions = <TProps>(Component: ComponentType<TProps & WithActions>): ComponentType<TProps> => {
    const mapDispatchToProps = (dispatch: Dispatch): WithActions => {
        return {
            actions: {
                setCounter: wrapDispatch(dispatch, createSetCounterAction),
                incrementCounter: wrapDispatch(dispatch, createIncrementCounterAction),
                setSuperheroName: wrapDispatch(dispatch, createSetSuperheroNameAction),
				setLogoDrawing: wrapDispatch(dispatch, createSetLogoDrawingAction),
                setLogoBackground: wrapDispatch(dispatch, createSetLogoBackgroundAction),
                tasks: {
                    addTask: wrapDispatch(dispatch, createAddTaskAction),
                    removeTask: wrapDispatch(dispatch, createRemoveTaskAction),
                    updateTask: wrapDispatch(dispatch, createUpdateTaskAction),
                    setCustomChecklist: wrapDispatch(dispatch, createSetCustomChecklist),
                },
                setCostumeSelection: wrapDispatch(dispatch, createSetCostumeSelection),
                setActivityCompletion: wrapDispatch(dispatch, createSetActivityCompletion),
                resetAll: wrapDispatch(dispatch, createResetAll),
                setQuizResult: wrapDispatch(dispatch, createSetQuizResultAction),
                setRescueRecord: wrapDispatch(dispatch, createSetRescueRecordAction),
            },
        };
    };

    return connect(null, mapDispatchToProps)(Component as ComponentType<unknown>);
};
