import React, { PropsWithChildren, useEffect, useState } from "react";
import { WithActions, withActions } from "../../../hocs/withActions";
import { WithState, withState } from "../../../hocs/withState";
import { ChecklistEntry } from "./ChecklistEntry";
import { ActivityCodes } from "../../../consts/ActivityCodes";
import { IActivityProps } from "../../../interfaces/activityProps";
import { GetNextActivityIndex } from "../../../consts/ActivityInfo";
import { ChecklistOptions } from "../../../consts/ChecklistOptions";
import { ConfirmationPopup } from "../../ConfirmationPopup";

const ChecklistDecorated = (props: PropsWithChildren<IActivityProps & WithActions & WithState>) => {
    const { checklist, customChecklist } = props.state;
    const { addTask, setCustomChecklist } = props.actions.tasks;
    const currentActivity = props.currentActivity ? props.currentActivity : ActivityCodes.checklist;
    const isDone = new Map<string, boolean>(props.state.completedActivities).get(currentActivity);
    const [showIntro, setShowIntro] = useState<boolean>(true);
    const [tasksComplete, setTasksComplete] = useState<number>(0);
    const [confirmReset, setConfirmReset] = useState<boolean>(false);
    const maxChecklistLength = 3;

    const isMaxed = (): boolean => {
        return checklist.length >= maxChecklistLength;
    };

    const RandomiseChecklist = () => {
        //If we have no tasks but aren't doing a custom list, initialise the list.
        if (checklist.length === 0 && !customChecklist) {
            const optionsCopy = [...ChecklistOptions];
            for (let i = 0; i < maxChecklistLength; ++i) {
                const index = Math.floor(Math.random() * optionsCopy.length);
                addTask(optionsCopy[index]);
                optionsCopy.splice(index, 1);
            }
        }
    };

    useEffect(() => {
        RandomiseChecklist();
    }, [customChecklist]);

    useEffect(() => {
        if (!isDone) {
            let completedTasks = 0;
            for (const entry of checklist) {
                if (entry.complete) {
                    ++completedTasks;
                }
            }

            if (completedTasks == checklist.length && checklist.length > 0) {
                props.actions.setActivityCompletion(currentActivity, true);
            }

            if (tasksComplete != completedTasks) {
                setTasksComplete(completedTasks);
            }
        }
    }, [checklist]);

    const OnAddNewTask = () => {
        addTask();
    };

    const EnableChecklist = () => {
        setShowIntro(false);
    };

    const EnableCustomChecklist = () => {
        setCustomChecklist(true);
        addTask();
    };

    const DisableCustomChecklist = () => {
        setCustomChecklist(false);
        setConfirmReset(false);
        EnableChecklist();
    };

    const OnResetPressed = () => {
        setConfirmReset(true);
    };

    const CancelReset = () => {
        setConfirmReset(false);
    };

    const NextActivity = () => {
        if (props.selectActivity) {
            props.selectActivity(GetNextActivityIndex(currentActivity));
        }
    };

    const DownloadPDF = () => {
        let sUrl = customChecklist ? require("../../../files/Empty-Checklist.pdf") : require("../../../files/Prefilled-Checklist.pdf"); //Todo: Get the actual file.

        //Creating new link node.
        const link = document.createElement("a");
        link.href = sUrl;

        if (link.download !== undefined) {
            //Set HTML5 download attribute. This will prevent file from opening if supported.
            link.download = "Checklist";
        }

        //Dispatching click event.
        if (document.createEvent) {
            const e = document.createEvent("MouseEvents");
            e.initEvent("click", true, true);
            link.dispatchEvent(e);
            return;
        }

        // Force file download (whether supported by server).
        if (!sUrl.includes("?")) {
            sUrl += "?download";
        }

        window.open(sUrl, "_self");
    };

    return (
        <>
            {showIntro && tasksComplete == 0 && !isDone && !customChecklist ? (
                <div className={"checklist-intro"}>
                    <div className="start-text step">Step 5:</div>
                    <div className="start-text title">Do good deeds</div>
                    <p>Check off your deeds when you complete them!</p>
                    <div className={"start-button checklist-start"} onClick={EnableChecklist}>
                        Start
                    </div>
                </div>
            ) : (
                <>
                    <div className="activity-header">{customChecklist ? "List of My Good Deeds" : "List of Good Deeds"}</div>
                    <div>
                        {checklist.map((entry, index) => {
                            return <ChecklistEntry key={index} taskID={index} {...entry} customChecklist={customChecklist} />;
                        })}
                    </div>
                    {customChecklist ? (
                        <>
                            {isMaxed() ? null : (
                                <div className={"add-task-button"} onClick={OnAddNewTask}>
                                    Add Good Deed!
                                </div>
                            )}
                            <div title="Restart Activity" className={"reset image"} onClick={OnResetPressed} />
                            <ConfirmationPopup onYes={DisableCustomChecklist} onNo={CancelReset} show={confirmReset}>
                                Are you <strong>sure</strong> you want to lose your list?
                            </ConfirmationPopup>
                        </>
                    ) : (
                        <div className={"custom-checklist-button"} onClick={EnableCustomChecklist}>
                            Make My Own List!
                        </div>
                    )}
                    <div className={"downloadLink"} onClick={DownloadPDF}>Download</div>
                    {isDone ? <div className={"continue image"} onClick={NextActivity} /> : null}
                </>
            )}
        </>
    );
};

export const Checklist = withActions(withState(ChecklistDecorated));
