import { actionReducer } from "../actionReducer";
import { isResetAllAction } from "../actionTests/isResetAllAction";
import { initialState } from "../initialState";
import { analytics, analyticsCategories } from "../../consts/Analytics";

export const resetAllReducer = actionReducer(
    initialState,
    () => {
        analytics.track("resetAll", {
            category: analyticsCategories.debug,
            label: analyticsCategories.debug,
        });
        return {
            ...initialState,
        };
    },
    isResetAllAction,
);
