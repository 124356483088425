import React, { PropsWithChildren } from "react";
import { ActivityInfo } from "../consts/ActivityInfo";

interface IProps {
    activityCode: string;
}

const HeaderDecorated = (props: PropsWithChildren<IProps>) => {
    return (
        <div className={"header"}>
            <div className="logo image" />
        </div>
    );
};

export const Header = HeaderDecorated;
