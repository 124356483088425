import React, { PropsWithChildren, useEffect, useState } from "react";
import { Cell } from "./Cell";
import { CellFunction } from "./Types/CellFunction";
import { CellState } from "./Types/CellState";
import { WithActions, withActions } from "../../../hocs/withActions";
import { WithAnalytics, withAnalytics } from "../../../hocs/withAnalytics";
import { analyticsCategories } from "../../../consts/Analytics";
import { Cell as FGCell } from "./Types/Cell";
import { ActivityCodes } from "../../../consts/ActivityCodes";
import { IActivityProps } from "../../../interfaces/activityProps";
import { GetNextActivityIndex } from "../../../consts/ActivityInfo";
import { withState, WithState } from "../../../hocs/withState";
import { ConfirmationPopup } from "../../ConfirmationPopup";

export interface IMinesweeperProps {
    height: number;
    width: number;
    grid: number[];
}

const FinderGameDecorated = (props: PropsWithChildren<IMinesweeperProps & IActivityProps & WithState & WithActions & WithAnalytics>) => {
    const analytics = props.analytics;
    const currentActivity = props.currentActivity ? props.currentActivity : ActivityCodes.finder;
    const isComplete = new Map<string, boolean>(props.state.completedActivities).get(currentActivity);

    //General State
    const [init, setInit] = useState(false);
    const [grid, setGrid] = useState<FGCell[][]>([]);
    const [gameState, setGameState] = useState("intro");
    const [showingHelp, showHelp] = useState(false);
    const [showIntro, setShowIntro] = useState<boolean>(true);
    const [confirmReset, setConfirmReset] = useState<boolean>(false);

    //Per Game
    const [movesLeft, setMovesLeft] = useState<number>(0);
    const [rockCount, setRockCount] = useState<number>(0);
    const [streak, setStreak] = useState<number>(0);

    //Per Round
    const [target, setTarget] = useState<number>(-1);
    const [startTime, setStartTime] = useState<number>(Date.now());
    const [digCount, setDigCount] = useState<number>(0);

    //Game Specs (Should this be setup somewhere else?)
    const gridWidth = 8;
    const gridHeight = 8;
    const moveCount = 10;
    const rockStep = 2;
    const maxRocks = 20;

    const NextActivity = () => {
        if (props.selectActivity) {
            props.selectActivity(GetNextActivityIndex(currentActivity));
        }
    };

    const Convert1DTo2D = (index: number): { x: number; y: number } => {
        return {
            x: Math.floor(index / gridWidth),
            y: index % gridWidth,
        };
    };

    const Convert2DTo1D = (x: number, y: number): number => {
        return y * gridWidth + x;
    };

    const RandomCoord = (): { x: number; y: number } => {
        return {
            x: Math.round(Math.random() * (gridWidth - 1)),
            y: Math.round(Math.random() * (gridHeight - 1)),
        };
    };

    // Initialize the minesweeper grid
    const initGrid = (reset = false) => {
        const gridDetails: FGCell[][] = [];

        for (let j = 0; j < gridHeight; j++) {
            const row: FGCell[] = [];
            for (let i = 0; i < gridWidth; i++) {
                const newCell: FGCell = {
                    x: i,
                    y: j,
                    state: CellState.Hidden,
                    isGoal: false,
                    direction: "up",
                };

                row.push(newCell);
            }
            gridDetails.push(row);
        }

        // Place Target
        let rand = RandomCoord();
        gridDetails[rand.x][rand.y].isGoal = true;
        setTarget(Convert2DTo1D(rand.x, rand.y));

        if (!reset) {
            //If not the first round, add some random blocked cells.
            //This can roll the same cell multiple times, but that's fine.
            for (let i = 0; i < rockCount; ++i) {
                rand = RandomCoord();
                //Don't block the goal.
                if (!gridDetails[rand.x][rand.y].isGoal) {
                    gridDetails[rand.x][rand.y].state = CellState.Blocked;
                }
            }
        }

        setGrid(gridDetails);
        setInit(true);
        setStartTime(Date.now());
        setDigCount(0);
    };

    const initGame = () => {
        setInit(false);
        initGrid();
        setMovesLeft(moveCount);
    };

    // Updates the cell in the grid
    const updateCell = (cell: FGCell) => {
        const newGrid = [...grid];

        const x = cell.x;
        const y = cell.y;

        newGrid[y][x] = cell;

        setGrid(newGrid);
    };

    const ResetGame = () => {
        setGameState("play");
        setRockCount(0);
        setStreak(0);
        setMovesLeft(moveCount);
        setShowIntro(false);
        setConfirmReset(false);
        setInit(false);
        initGrid(true);
    };

    const onTryAgain = () => {
        analytics.track("finderFinished", {
            category: analyticsCategories.finder,
            label: "retry",
            metric1: digCount,
            metric2: streak,
            metric4: (Date.now() - startTime) / 1000, //Time in seconds
        });

        ResetGame();
    };

    const onResetClicked = () => {
        analytics.track("finderFinished", {
            category: analyticsCategories.finder,
            label: "reset",
            metric1: digCount,
            metric2: streak,
            metric3: movesLeft,
            metric4: (Date.now() - startTime) / 1000, //Time in seconds
        });

        setConfirmReset(true);
    };

    const CancelReset = () => {
        setConfirmReset(false);
    };

    const onCellClicked: CellFunction = (cell) => {
        if (gameState != "play") {
            return;
        }

        if (cell.state != CellState.Hidden) {
            return;
        }

        let movesRemaining = movesLeft - 1;
        setDigCount(digCount + 1);
        setMovesLeft(movesRemaining);
        cell.state = CellState.Cleared;

        if (!cell.isGoal) {
            const targetPosition = Convert1DTo2D(target);
            const xDif = targetPosition.x - cell.x;
            const yDif = targetPosition.y - cell.y;

            //Check which direction is the furthest to the goal.
            //Default direction is up.
            if (Math.abs(xDif) > Math.abs(yDif)) {
                cell.direction = "left";
                if (xDif > 0) {
                    cell.direction = "right";
                }
            } else if (yDif > 0) {
                cell.direction = "down";
            }

            if (movesRemaining < 1) {
                setGameState("lose");
                props.actions.setActivityCompletion(currentActivity, true);

                if (props.state.rescueRecord < streak) {
                    props.actions.setRescueRecord(streak);
                }

                analytics.track("finderFinished", {
                    category: analyticsCategories.finder,
                    label: "lose",
                    metric1: digCount,
                    metric2: streak,
                    metric4: (Date.now() - startTime) / 1000, //Time in seconds
                });
            }
        } else {
            setGameState("win");
            setStreak(streak + 1);

            movesRemaining += 3;
            setMovesLeft(movesRemaining); //Gain a small boost to moves.
            setRockCount(Math.min(rockCount + rockStep, maxRocks));

            analytics.track("finderFinished", {
                category: analyticsCategories.finder,
                label: "win",
                metric1: digCount,
                metric2: streak,
                metric3: movesRemaining,
                metric4: (Date.now() - startTime) / 1000, //Time in seconds
            });
        }

        updateCell(cell);
    };

    const NextGame = () => {
        setGameState("play");
        initGrid();
    };

    const pluralizeCitizens = (count: number): string => {
        return `citizen${count != 1 ? "s" : ""}`;
    };

    const RenderGameState = () => {
        switch (gameState) {
            case "win":
                return (
                    <div>
                        <div className="activity-header">You rescued a lost citizen!</div>
                        <div className={"next-game-button"} onClick={NextGame}>
                            Rescue another citizen
                        </div>
                    </div>
                );

            case "lose":
                return (
                    <div>
                        <div className="activity-header">You ran out of moves!</div>
                        You rescued {streak} {pluralizeCitizens(streak)}!
                    </div>
                );

            default:
                return null;
        }
    };

    const renderGrid = () => {
        const rows = [];

        if (init) {
            // Dynamically create HTML for grid
            for (let j = 0; j < gridHeight; j++) {
                const column = [];

                for (let i = 0; i < gridWidth; i++) {
                    // Add starting grid
                    if (i === 0) {
                        column.push(<div key={i - 1} className={"border"} />);
                    }

                    column.push(<Cell key={i} cell={grid[j][i]} onClicked={onCellClicked} />);

                    // Add finish grid
                    if (i === gridWidth - 1) {
                        column.push(<div key={i + 1} className={"border"} />);
                    }
                }

                rows.push(
                    <div className={"gridrow"} key={j}>
                        {" "}
                        {column}
                    </div>,
                );
            }

            return <div className={"grid"}>{rows}</div>;
        } // Initialize the grid
        else {
            return <> Loading </>;
        }
    };

    // called when help pressed
    const displayHelpPopup = (display: boolean) => {
        showHelp(display);
    };

    // Render popup if it's being displayed
    const renderHelp = () => {
        let returnValue = <></>;

        if (showingHelp) {
            returnValue = (
                <div className={"popupBackground"}>
                    <div className={"popup tutorial-popup"}>
                        <div className={"tutorial"}>
                            <div className={"tutorial-section"}>
                                <div className={"tutorial-image"} style={{ backgroundImage: "url(" + require("../../../images/FinderTutorials/Tutorial_1.png") + ")" }} />
                                <div className={"tutorial-text"}>Find the lost citizen to win the game!</div>
                            </div>
                            <div className={"tutorial-section"}>
                                <div className={"tutorial-image"} style={{ backgroundImage: "url(" + require("../../../images/FinderTutorials/Tutorial_2.png") + ")" }} />
                                <div className={"tutorial-text"}>Tap a square to search for the lost citizen.</div>
                            </div>
                            <div className={"tutorial-section"}>
                                <div className={"tutorial-image"} style={{ backgroundImage: "url(" + require("../../../images/FinderTutorials/Tutorial_3.png") + ")" }} />
                                <div className={"tutorial-text"}>Some squares will contain hints that point towards the lost citizen.</div>
                            </div>
                            <div className={"tutorial-section"}>
                                <div className={"tutorial-image"} style={{ backgroundImage: "url(" + require("../../../images/FinderTutorials/Tutorial_4.png") + ")" }} />
                                <div className={"tutorial-text"}>Squares with rocks cannot be searched.</div>
                            </div>
                            <div className={"tutorial-section"}>
                                <div className={"tutorial-image"} style={{ backgroundImage: "url(" + require("../../../images/FinderTutorials/Tutorial_5.png") + ")" }} />
                                <div className={"tutorial-text"}>Find as many lost citizens as you can before you run out of moves!</div>
                            </div>
                        </div>
                        <div className={"buttons"}>
                            <div className={"button ok"} onClick={() => displayHelpPopup(false)}>
                                OK
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return returnValue;
    };

    const TestAll = () => {
        grid.map((row) => {
            row.map((cell) => {
                onCellClicked(grid[cell.y][cell.x]);
            });
        });
    };

    // Run when component mounted
    useEffect(() => {
        initGame();
    }, []);

    // The layout of the canvas options
    const buttonLayout = (
        <div className={"sweeperbuttons"}>
            <div title="Restart Activity" className={"reset image"} onClick={onResetClicked} />
            <div className={"minesweeper-help"} onClick={() => displayHelpPopup(true)} />
        </div>
    );

    const gameStats = (
        <div className={"game-stats"}>
            <div className="stat">
                <p>Moves Left: </p>
                <div>
                    <p>{movesLeft}</p>
                </div>
            </div>
            <div className="stat">
                <p>Citizens Rescued: </p>
                <div>
                    <p>{streak}</p>
                </div>
            </div>
        </div>
    );

    const intro = (
        <div className={"finder-intro"}>
            <div className="start-text step">Step 6:</div>
            <div className="start-text title">Save Citizens!</div>
            <p>Tap the grid and follow the arrows to rescue as many citizens as you can!</p>
            <div className={"start-button"} onClick={ResetGame}>
                Start
            </div>
        </div>
    );

    // Return HTML
    return (
        <div className={"minesweeper"}>
            {showIntro ? (
                intro
            ) : (
                <>
                    {renderGrid()}
                    {gameStats}
                    {buttonLayout}
                    {renderHelp()}
                    <div className={"minesweeper-state"}>{RenderGameState()}</div>
                    <ConfirmationPopup onYes={ResetGame} onNo={CancelReset} show={confirmReset}>
                        Are you <strong>sure</strong> you want to reset your game?
                    </ConfirmationPopup>
                </>
            )}

            {isComplete ? (
                <div className={"continue image"} onClick={NextActivity}>
                </div>
            ) : null}
        </div>
    );
};

export const FinderGame = withState(withActions(withAnalytics(FinderGameDecorated)));
