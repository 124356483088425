import { actionReducer } from "../actionReducer";
import { isRemoveTaskAction } from "../actionTests/isRemoveActionTask";
import { initialState } from "../initialState";
import { analytics, analyticsCategories } from "../../consts/Analytics";

export const removeTaskReducer = actionReducer(
    initialState,
    (previousState = initialState, action) => {
        analytics.track("removeTask", {
            category: analyticsCategories.checklist,
            label: action.taskID,
        });
        //Remove the given task from the list.
        const checklist = [...previousState.checklist];
        checklist.splice(action.taskID, 1);

        return {
            ...previousState,
            checklist,
        };
    },
    isRemoveTaskAction,
);
