import { ReduxActionType } from "../../../common/types/ReduxActionType";
import { SetActivityCompletionAction } from "../actions/SetActivityCompletionAction";

export const createSetActivityCompletion = (activityCode: string, complete: boolean): SetActivityCompletionAction => {
    return {
        type: ReduxActionType.SetActivityCompletion,
        activityCode,
        complete,
    };
};
