import React, { PropsWithChildren, useEffect, useState } from "react";
import { IWithThumbnailFile } from "../../../interfaces/quizzes/IFile";
import { IQuizAnswer } from "../../../interfaces/quizzes/IQuizAnswer";
import { IQuizResult } from "../../../interfaces/quizzes/IQuizResult";
import { PopulatedQuiz } from "../../../interfaces/quizzes/PopulatedQuiz";
import { PopulatedQuizQuestion } from "../../../interfaces/quizzes/PopulatedQuizQuestion";
import { IWithAnalytics, withAnalytics } from "../../../hocs/withAnalytics";
import { IPopupComponentProps } from "../../../interfaces/quizzes/IComponentModuleLoader";
import { QuizResult } from "./QuizResult";
import { QuizQuestion } from "./QuizQuestion";
import { selectQuizResult } from "./selectQuizResult";
import { WithActions, withActions } from "../../../hocs/withActions";
import { analyticsCategories } from "../../../consts/Analytics";
import { ActivityCodes } from "../../../consts/ActivityCodes";
import { WithState, withState } from "../../../hocs/withState";
import { IActivityProps } from "../../../interfaces/activityProps";
import { GetNextActivityIndex } from "../../../consts/ActivityInfo";
import { QuizIntro } from "./QuizIntro";
import { ConfirmationPopup } from "../../ConfirmationPopup";

export interface IProps extends IPopupComponentProps<PopulatedQuiz> {
    // By default, we'll show the result in a new popup (allowing a new header, showing related content, etc.)
    // If this value is true we'll just show the result in the existing popup
    displayResultInline?: boolean;
}

const QuizPopupContentDecorated = (props: PropsWithChildren<IProps & IActivityProps & WithState & IWithAnalytics & WithActions>) => {
    const { entity, showPopup, displayResultInline, state } = props;
    const currentActivity = props.currentActivity ? props.currentActivity : ActivityCodes.quiz;
    const { linkedContent } = entity;

    const [result, setResult] = useState<(IQuizResult & IWithThumbnailFile) | undefined>(undefined);
    const [currentQuestion, setCurrentQuestion] = useState<PopulatedQuizQuestion>(linkedContent.quizQuestions[0]);
    const [selectedAnswers, setSelectedAnswers] = useState<IQuizAnswer[]>([]);
    const [quizStarted, setQuizStarted] = useState<boolean>(false);
    const [confirmReset, setConfirmReset] = useState<boolean>(false);

    useEffect(() => {
        setQuizStarted(false);
    }, []);

    const StartQuiz = () => {
        setQuizStarted(true);
    };

    const onAnswerSelected = (quizQuestion: PopulatedQuizQuestion, quizAnswer: IQuizAnswer) => {
        props.analytics.track("quizQuestionAnswered", {
            category: analyticsCategories.quiz,
            label: quizQuestion.label,
            answer: quizAnswer.label,
        });

        const newSelectedAnswers = [...selectedAnswers, quizAnswer];
        setSelectedAnswers(newSelectedAnswers);
        const index = entity.linkedContent.quizQuestions.indexOf(quizQuestion);
        if (index < entity.linkedContent.quizQuestions.length - 1) {
            setCurrentQuestion(entity.linkedContent.quizQuestions[index + 1]);
        } else {
            const newResult = selectQuizResult(linkedContent.quizType, entity.linkedContent.quizResults, newSelectedAnswers);
            props.analytics.track("quizComplete", {
                category: analyticsCategories.quiz,
                label: newResult.label,
            });

            props.actions.setActivityCompletion(currentActivity, true);
            props.actions.setQuizResult(newResult.id);

            if (displayResultInline) {
                setResult(newResult);
            } else {
                showPopup(
                    QuizResult,
                    {
                        entity,
                        quizResult: newResult,
                    },
                    {
                        header: `${entity.linkedContent.label} - Result`,
                        relatedContentId: `-1`,
                        showRelatedContent: true,
                        nested: true,
                    },
                );
            }
        }
    };

    const ResetQuiz = () => {
        props.actions.setActivityCompletion(currentActivity, false);
        props.actions.setQuizResult(-1);
        setResult(undefined);
        setSelectedAnswers([]);
        setCurrentQuestion(linkedContent.quizQuestions[0]);
        setQuizStarted(true); //Change this to false if the quiz should ALWAYS show the intro.
        setConfirmReset(false);
    };

    const OnResetPressed = () => {
        setConfirmReset(true);
    };

    const CancelReset = () => {
        setConfirmReset(false);
    };

    const NextActivity = () => {
        if (props.selectActivity) {
            props.selectActivity(GetNextActivityIndex(currentActivity));
        }
    };

    if (state.quizResult > -1 && !result) {
        setResult(
            entity.linkedContent.quizResults.find((entry) => {
                return entry.id == state.quizResult;
            }),
        );
    }

    // If still asking questions
    if (result === undefined) {
        if (quizStarted) {
            return (
                <div className={["QuizColumn", `quiz-type-${entity.linkedContent.quizType}`].join(" ")}>
                    <div className={"Details"}>
                        <div className={"Title noselect"}>{currentQuestion.label}</div>
                    </div>
                    <div className={"QuizList"}>
                        <QuizQuestion key={currentQuestion.id} onAnswerSelected={onAnswerSelected} quiz={linkedContent} currentQuizQuestion={currentQuestion} quizQuestion={currentQuestion} />
                    </div>
                    <div id={"quizname"} className={"quizname hidden"} />
                </div>
            );
        } else {
            return <QuizIntro startQuiz={StartQuiz} />;
        }

        // If we have a result
    } else {
        return (
            <>
                <QuizResult quizResult={result} entity={entity} />
                <div className="quiz-finish-buttons">
                    <div title="Restart Activity" className={"reset image"} onClick={OnResetPressed} />
                    <div className={"continue image"} onClick={NextActivity} />
                </div>
                <ConfirmationPopup onYes={ResetQuiz} onNo={CancelReset} show={confirmReset}>
                    Are you <strong>sure</strong> you would like to take the quiz again?
                </ConfirmationPopup>
            </>
        );
    }
};

export const QuizPopupContent = withAnalytics(withState(withActions(QuizPopupContentDecorated)));
