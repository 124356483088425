import React, { PropsWithChildren, useEffect } from "react";
import { IWithAnalytics, withAnalytics } from "../../../hocs/withAnalytics";
import { WithState, withState } from "../../../hocs/withState";
import { GetNextIncompleteActivityIndex } from "../../../consts/ActivityInfo";
import { WithActions, withActions } from "../../../hocs/withActions";
import { IActivityProps } from "../../../interfaces/activityProps";
import { ActivityCodes } from "../../../consts/ActivityCodes";
import { QuizData } from "../../../consts/QuizData";
import { CostumeParts } from "../../../consts/CostumeParts";

const OutroDecorated = (props: PropsWithChildren<IActivityProps & WithState & WithActions & IWithAnalytics>) => {
    const { state, actions, analytics } = props;
    const { superheroName, costumeSelections, checklist, quizResult, logoDrawing, rescueRecord } = state;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const logoBackgroundPath = require(`../Drawing/Backgrounds/${state.logoBackground}`);

    useEffect(() => {
        analytics.track("reachedOutro", {
            category: ActivityCodes.outro,
            label: "isAHero",
            value: true,
        });

        actions.setActivityCompletion(ActivityCodes.outro, true);
    }, []);

    const GetPowerName = (): string => {
        if (quizResult > -1) {
            return QuizData.linkedContent.quizResults[quizResult].label;
        }

        return "Super Cool";
    };

    const GetHeadCostume = (): string => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const imagePath = require(`../../../images/${CostumeParts[0][costumeSelections[0]]}`);
        return `url('${imagePath}')`;
    };

    return (
        <>
            <div className={"hero-id"}>
                <div className="activity-header outro-header">
                    <div className="start-text step">Congratulations</div>
                    You&rsquo;re Sanctuary City&rsquo;s newest Hero!</div>
                <p>Here&rsquo;s your official Hero ID Card</p>
                <div className="id-card">
                    <div className="id-images">
                        <div className={"hero-id-costume"} style={{ backgroundImage: GetHeadCostume(), width: "100%", height: "50%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }} />
                        <div className={"hero-id-logo"} style={{ backgroundImage: `url('${logoDrawing}')`, width: "100%", height: "45%", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                            <div className={"hero-id-logo-background"} style={{ backgroundImage: `url('${logoBackgroundPath}`, width: "100%", height: "100%", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }} />
                        </div>
                    </div>
                    <div className="id-text">
                        <div className={"hero-id-name"}>{superheroName}</div>
                        <div className={"hero-id-power"}>Power: {GetPowerName()}</div>
                        <div className={"hero-id-tasks"}>GOOD DEEDS: {checklist.length}</div>
                        <div className={"hero-id-rescues"}>RECORD RESCUES: {rescueRecord}</div>
                    </div>
                </div>
            </div>

            <div>
                <a className="explore" href={"https://hub.visitsanctuarycity.com/"} rel={"noopener noreferrer"} target="_blank">
                    Now explore Sanctuary City!
                </a>
            </div>
        </>
    );
};

export const Outro = withActions(withState(withAnalytics(OutroDecorated)));
