import React, { PropsWithChildren, useState } from "react";
import FlipPage from "react-flip-page";
import { ActivityInfo } from "../consts/ActivityInfo";
import { withAnalytics, WithAnalytics } from "../hocs/withAnalytics";
import { ActivitySelector } from "./Activities/ActivitySelector";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { ActivityCodes } from "../consts/ActivityCodes";

// Images
import background_1 from "../images/Book-Background.png";
import background_2 from "../images/blue-background-02.jpg";

interface IProps {
    path: string;
}

export const MainDecorated = (props: PropsWithChildren<IProps & WithAnalytics>) => {
    const { children, analytics } = props;
    const [currentActivity, setCurrentActivity] = useState<string>(ActivityCodes.intro);

    // Page values
    let flipPage: FlipPage | undefined = undefined;

    const pageAnimateTime = 800;

    // State
    const [currentPage, setPage] = useState(0); // Currently visible page
    const [flipping, setFlipping] = useState(false);

    // Update the current selected activity
    const selectActivity = (id: number) => {
        // Keep id within page boundaries
        id = Math.min(Math.max(0, id), [...ActivityInfo].length - 1);

        if (!!flipPage && !flipping) {
            // Keep track of the current page
            if (currentPage != id) {
                // Calculate operation
                if (Math.abs(id - currentPage) > 1) {
                    flipPage.gotoPage(id);
                } else if (id > currentPage) {
                    flipPage.gotoNextPage();

                } else if (id < currentPage) {
                    flipPage.gotoPreviousPage();
                }
            }
        }
    };

	// Store backgrounds for each page
	const pageBackgrounds = [
		background_2,
		background_1,
		background_2,
		background_1,
		background_2,
		background_1,
		background_2,
		background_1
	];


    // Don't render page content while flipping pages
    const renderPageContent = () => {
        if (flipping) {
            return null;
        } else {
            const baseElement = ActivityInfo.get(currentActivity)?.element;
            let element = null;
            if (baseElement) {
                element = React.cloneElement(baseElement, { selectActivity, currentActivity });
            }

            return <>{element}</>;
        }
    };

    // Return HTML
    return (
        <>
            <Header activityCode={currentActivity} />
            <div className="activity">
                <div className="flipBook">
                    <div className={"flip-parent"}>
                        <FlipPage
                            ref={(component) => {
                                if (component) {
                                    flipPage = component;
                                }
                            }}
                            orientation={"horizontal"}
                            responsive={true}
                            uncutPages={true}
                            animationDuration={pageAnimateTime}
                            className={"flipPage"}
                            disableSwipe={true}
                            swipeImmune={["page", "contentOverlay", "pageContent"]}
                            onStartPageChange={() => {
                                setFlipping(true);
                            }}
                            onPageChange={(pageIndex: number) => {
                                setFlipping(false);
                                setPage(pageIndex);
                                setFlipping(false);

                                // Update the current activity
                                const key = [...ActivityInfo.keys()][pageIndex];
                                analytics.track("selectedActivity", {
                                    category: key,
                                    label: currentActivity,
                                });
                                setCurrentActivity(key);
                            }}
                        >
                            {[...ActivityInfo.keys()].map((entry, index) => {
                                return (
                                    <div className={`page ${entry}-page`} key={index} style={{ backgroundImage:`url(${pageBackgrounds[index]})` }} />
                                );
                            })}
                        </FlipPage>

                        <div className={"contentOverlay"}>
                            <div className={"pageContent"}>{renderPageContent()}</div>
                        </div>
                    </div>
                </div>
            </div>
            <ActivitySelector currentSelection={currentPage} setSelection={selectActivity} />
            {children}
            <Footer selectActivity={selectActivity} />
        </>
    );
};

export const Main = withAnalytics(MainDecorated);
