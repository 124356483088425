import React, { PropsWithChildren } from "react";
import { Cell as FGCell } from "./Types/Cell";
import { CellFunction } from "./Types/CellFunction";
import { CellState } from "./Types/CellState";

interface IProps {
    cell: FGCell;
    onClicked: CellFunction;
}

export const Cell = (props: PropsWithChildren<IProps>) => {
    const { cell, onClicked } = props;

    //Todo: Remove the entire render cell and replace it with just images.
    const renderCell = () => {
        let returnValue = <div />;

        if (cell.state === CellState.Cleared) {
            if (!cell.isGoal) {
                returnValue = <div className={"cellNumber"}> {GetArrow(cell.direction)} </div>;
            } else {
                returnValue = <div className={"cellNumber"}><div className="citizen image"/></div>;
            }
        }

        return <>{returnValue}</>;
    };

    //Todo: Can be removed once we have arrow images.
    const GetArrow = (direction: string): string => {
        switch (direction) {
            case "left":
                //return "🡐";
            case "right":
                //return "🡒";
            case "down":
                //return "🡓";
            default:
                //return "🡑";
        }
    };

    const cellClass = (): string => {
        let output = "";

        if (cell.isGoal) {
            output += " goal";
        } else {
            output += ` ${cell.direction}`;
        }

        if (cell.state === CellState.Cleared) {
            output += " cleared";
        } else if (cell.state === CellState.Blocked) {
            output += " blocked";
        }

        return output;
    };

    return (
        <div className={"gridcell" + cellClass()} onClick={() => onClicked(cell)}>
            {renderCell()}
        </div>
    );
};
