import * as PropTypes from "prop-types";
import React, { PropsWithChildren, useState } from "react";
import { canvasToBlob, composeFn, fileToUrl, importImage, revokeUrl } from "./util";
import { withActions, WithActions } from "../../../hocs/withActions";
import { WithState, withState } from "../../../hocs/withState";
import { StoreDrawing } from "./Types/StoreDrawing";
import { ActivityCodes } from "../../../consts/ActivityCodes";
import { IActivityProps } from "../../../interfaces/activityProps";
import { ActivityInfo, GetNextActivityIndex, GetNextIncompleteActivityIndex } from "../../../consts/ActivityInfo";
import { ConfirmationPopup } from "../../ConfirmationPopup";
import { backgrounds } from "../../../consts/PainterBackgrounds";

// disable touchAction, else the draw on canvas would not work
// because window would scroll instead of draw on it
const setUpForCanvas = () => {
    document.body.style.touchAction = "none";
};

const cleanUpCanvas = () => {
    document.body.style.touchAction = null;
};

export type LineJoinType = "round" | "bevel" | "miter";
export type LineCapType = "round" | "butt" | "square";

export interface CanvasProps {
    onMouseDown: React.MouseEventHandler<HTMLCanvasElement>;
    onTouchStart: React.TouchEventHandler<HTMLCanvasElement>;
    onMouseMove: React.MouseEventHandler<HTMLCanvasElement>;
    onTouchMove: React.TouchEventHandler<HTMLCanvasElement>;
    onMouseUp: React.MouseEventHandler<HTMLCanvasElement>;
    onTouchEnd: React.TouchEventHandler<HTMLCanvasElement>;
    style: React.CSSProperties;
    ref: (ref: HTMLCanvasElement) => void;
}

export interface PropsGetterInput extends Partial<CanvasProps> {
    [key: string]: any;
}

export interface PropsGetterResult extends CanvasProps {
    [key: string]: any;
}

export interface RenderProps {
    canvas: JSX.Element;
    triggerSave: () => void;
    getCanvasProps: (props: PropsGetterInput) => PropsGetterResult;
    imageCanDownload: boolean;
    imageDownloadUrl: string;
    setColor: (color: string) => void;
    setLineWidth: (width: number) => void;
    setLineJoin: (type: LineJoinType) => void;
    setLineCap: (type: LineCapType) => void;
}

export interface ReactPainterProps {
    height?: number;
    width?: number;
    initialColor?: string;
    initialLineWidth?: number;
    initialLineJoin?: LineJoinType;
    initialLineCap?: LineCapType;
    onSave?: (img: string) => void;
    render?: (props: RenderProps) => JSX.Element;
}

export interface PainterState {
    canvasHeight: number;
    canvasWidth: number;
    imageCanDownload: boolean;
    imageDownloadUrl: string;
    isDrawing: boolean;
    color: string;
    lineWidth: number;
    lineJoin: LineJoinType;
    lineCap: LineCapType;
    showPopup?: boolean;
    showIntro?: boolean;
    erasing: boolean;
    background: string;
}

class ReactPainterDecorated extends React.Component<ReactPainterProps & IActivityProps & WithState & WithActions, PainterState> {
    static propTypes = {
        color: PropTypes.string,
        background: PropTypes.string,
        tool: PropTypes.string,
        erasing: PropTypes.bool,
        stamp: PropTypes.string,
        height: PropTypes.number,
        lineCap: PropTypes.string,
        lineJoin: PropTypes.string,
        lineWidth: PropTypes.number,
        onSave: PropTypes.func,
        render: PropTypes.func,
        width: PropTypes.number,
    };

    static defaultProps: Partial<ReactPainterProps> = {
        height: 300,
        image: "",
        onSave() {
            // noop
        },
        initialColor: `#242424`,
        initialBackground: "background-1.png",
        initialLineCap: "round",
        initialLineJoin: "round",
        initialLineWidth: 10,
        width: 300,
        initialTool: "brush",
        initialStamp: "triangle",
    };

    canvasRef: HTMLCanvasElement = null;
    ctx: CanvasRenderingContext2D = null;
    lastX = 0;
    lastY = 0;
    scalingFactor = 1;

    state: PainterState = {
        canvasHeight: 0,
        canvasWidth: 0,
        color: this.props.initialColor,
        background: this.props.initialBackground,
        imageCanDownload: null,
        imageDownloadUrl: null,
        isDrawing: false,
        lineCap: this.props.initialLineCap,
        lineJoin: this.props.initialLineJoin,
        lineWidth: this.props.initialLineWidth,
        tool: this.props.initialTool,
        erasing: false,
        stamp: this.props.initialStamp,
        showPopup: false,
        showIntro: true,
    };

    extractOffSetFromEvent = (e: React.SyntheticEvent<HTMLCanvasElement>) => {
        const evt = e.nativeEvent;
        const { offsetX, offsetY } = evt as any;
        const clientX = evt.targetTouches ? evt.targetTouches[0].clientX : evt.clientX;
        const clientY = evt.targetTouches ? evt.targetTouches[0].clientY : evt.clientY;

        if (offsetX && offsetY) {
            return {
                offsetX: offsetX * this.scalingFactor,
                offsetY: offsetY * this.scalingFactor,
            };
        }

        const rect = this.canvasRef.getBoundingClientRect();

        const x = (clientX - rect.left) * this.scalingFactor;
        const y = (clientY - rect.top) * this.scalingFactor;

        return {
            offsetX: x,
            offsetY: y,
        };
    };

    private setCanvasDimensions(canvasWidth: number, canvasHeight: number) {
        if (canvasWidth !== this.state.canvasWidth || canvasHeight !== this.state.canvasHeight) {
            this.setState({
                canvasWidth,
                canvasHeight,
            });
        }
    }

    // Set up the canvas for drawing
    initializeCanvas = (width: number, height: number, imgWidth?: number, imgHeight?: number) => {
        if (this.canvasRef != null) {
            if (imgWidth && imgHeight) {
                const [cvWidth, cvHeight, scalingRatio] = this.getDrawImageCanvasSize(width, height, imgWidth, imgHeight);
                this.canvasRef.width = imgWidth;
                this.canvasRef.height = imgHeight;
                this.setCanvasDimensions(cvWidth, cvHeight);
                this.scalingFactor = 1 / scalingRatio;
            } else {
                this.canvasRef.width = width;
                this.canvasRef.height = height;
                this.setCanvasDimensions(width, height);
            }
            const { color, lineWidth, lineJoin, lineCap } = this.state;

            this.ctx.strokeStyle = color;
            this.ctx.lineWidth = lineWidth * this.scalingFactor;
            this.ctx.lineJoin = lineJoin;
            this.ctx.lineCap = lineCap;
        }
    };

    getDrawImageCanvasSize = (cWidth: number, cHeight: number, imageWidth: number, imageHeight: number) => {
        if (imageWidth <= cWidth) {
            return [imageWidth, imageHeight, 1];
        }
        const scalingRatio = cWidth / imageWidth;
        return [cWidth, scalingRatio * imageHeight, scalingRatio];
    };

    handleMouseDown = (e: React.SyntheticEvent<HTMLCanvasElement>) => {
        const { offsetX, offsetY } = this.extractOffSetFromEvent(e);
        this.lastX = offsetX;
        this.lastY = offsetY;

        this.setState({
            isDrawing: true,
        });

        this.props.actions.setActivityCompletion(ActivityCodes.painter, true);

        this.handleDraw(offsetX, offsetY);
    };

    handleMouseMove = (e: React.SyntheticEvent<HTMLCanvasElement>) => {
        const { offsetX, offsetY } = this.extractOffSetFromEvent(e);
        if (this.state.isDrawing) {
            this.handleDraw(offsetX, offsetY);
        }
    };

    handleDraw = (offsetX: number, offsetY: number) => {
        const { color, lineWidth, lineCap, lineJoin } = this.state;

        if (this.state.erasing) {
            const eraseScale = 2;

            const eraseWidth = lineWidth * eraseScale * this.scalingFactor;
            const eraseHeight = lineWidth * eraseScale * this.scalingFactor;

            const ctx = this.ctx;

            const lastX = this.lastX - eraseWidth / 2;
            const lastY = this.lastY - eraseHeight / 2;

            ctx.clearRect(lastX, lastY, eraseWidth, eraseHeight);

            this.lastX = offsetX;
            this.lastY = offsetY;
        } else if (this.state.tool === "brush") {
            const ctx = this.ctx;
            ctx.strokeStyle = color;
            ctx.lineWidth = lineWidth * this.scalingFactor;
            ctx.lineCap = lineCap;
            ctx.lineJoin = lineJoin;
            const lastX = this.lastX;
            const lastY = this.lastY;
            ctx.beginPath();
            ctx.moveTo(lastX, lastY);
            ctx.lineTo(offsetX, offsetY);
            ctx.stroke();
            this.lastX = offsetX;
            this.lastY = offsetY;
        } else if (this.state.tool === "stamp") {
            const ctx = this.ctx;
            ctx.strokeStyle = color;
            ctx.lineWidth = (lineWidth / 2) * this.scalingFactor;
            ctx.lineCap = lineCap;
            ctx.lineJoin = lineJoin;
            const lastX = this.lastX;
            const lastY = this.lastY;

            const l_scale = lineWidth / 5;
            const d_x = lastX + (offsetX - lastX) / 2;
            const d_y = lastY + (offsetY - lastY) / 2;

            // Draw the stamps
            if (this.state.stamp === "star") {
                this.drawStar(d_x, d_y, 7 * l_scale, 12 * l_scale, 5);
            } else if (this.state.stamp === "triangle") {
                this.drawStar(d_x, d_y, 6 * l_scale, 12 * l_scale, 3);
            } /*else if (this.state.stamp === "circle") {
					this.drawStar(d_x,d_y, 10 * l_scale, 10 * l_scale, 10);
				}*/

            this.lastX = offsetX;
            this.lastY = offsetY;
        }
    };

    // Draw a star to the canvas
    drawStar = (drawX: number, drawY: number, inner: number, outer: number, points: number) => {
        const ctx = this.ctx;

        // Star
        ctx.beginPath();
        const xZ = drawX - length / 2;
        const yZ = drawY - length / 2;
        let rot = (Math.PI / 2) * 3;
        let x = xZ;
        let y = yZ;
        const step = Math.PI / points;

        ctx.beginPath();
        ctx.moveTo(xZ, yZ - outer);
        for (let i = 0; i < points; i++) {
            x = xZ + Math.cos(rot) * outer;
            y = yZ + Math.sin(rot) * outer;
            ctx.lineTo(x, y);
            rot += step;

            x = xZ + Math.cos(rot) * inner;
            y = yZ + Math.sin(rot) * inner;
            ctx.lineTo(x, y);
            rot += step;
        }
        ctx.lineTo(xZ, yZ - outer);
        ctx.closePath();

        ctx.stroke();
    };

    handleMouseUp = (e: React.SyntheticEvent<HTMLCanvasElement>) => {
        this.setState({
            isDrawing: false,
        });

        // Store current drawing
        const { onSave } = this.props;
        const canvas = this.canvasRef;

        // convert canvas to image
        const canvasImageURL = canvas.toDataURL("image/png");
        const canvasImage = new Image();
        canvasImage.src = canvasImageURL;

        // Save drawing
        this.state.saveImage = canvasImage;
        onSave(this.state.saveImage);
    };

    // Update the canvas image
    loadStateImage = (loadImage: string) => {
        // Make sure provided image is a valid image
        if (loadImage != "") {
            this.resetCanvas();
            const ctx = this.ctx;
            const img = new Image();
            img.addEventListener("load", () => {
                if (ctx != null) {
                    ctx.drawImage(img, 0, 0);
                }
            });
            img.src = loadImage;

            this.setState({
                background: this.props.state.logoBackground,
            });
        } else {
            console.log("Image Not Valid");
        }
    };

    // Save the drawing to the state
    saveDrawing = (drawing) => {
        if (drawing != null && drawing != "") {
            //     console.log("save drawing:", drawing);
            this.props.actions.setLogoDrawing(drawing);
        }
    };

    handleSave = (onDone: (url: string) => void = () => {}) => {
        const { onSave } = this.props;

        const canvas = this.canvasRef;
        const ctx = this.ctx;

        // convert canvas to image
        const canvasImageURL = canvas.toDataURL("image/png");
        const canvasImage = new Image();
        canvasImage.src = canvasImageURL;

        // Save drawing
        this.saveDrawing(canvasImageURL);

        this.state.saveImage = canvasImage;

        // Create background image
        const backgroundImage = new Image();
        const backgrounds = require.context("./Backgrounds", true);
        const icons = require.context("../../../images", true);

        backgroundImage.src = backgrounds("./" + this.state.background);

        const loadFunction = () => {
            // calculate maths for drawing background image
            const imgWidth = backgroundImage.width;
            const imgHeight = backgroundImage.height;
            const canvasWidth = canvasImage.width;
            const canvasHeight = canvasImage.height;

            const ratio = imgWidth / imgHeight;

            // 'fit' the image to the canvas
            let drawW = canvasWidth;
            let drawH = canvasHeight;

            if (imgWidth > imgHeight) {
                drawH = drawW / ratio;
            } else {
                drawW = drawH * ratio;
            }

            // Center background
            const backgroundOffsetY = 2;

            const drawX = (canvasWidth - drawW) / 2;
            const drawY = (canvasHeight - drawH) / 2 + backgroundOffsetY;

            // Draw background image and drawing to canvas

            ctx.drawImage(canvasImage, 0, 0);
            ctx.drawImage(backgroundImage, drawX, drawY, drawW, drawH);

            // Draw watermark

            drawW = 100;
            drawH = 50;

            const l_watermark = new Image();
            l_watermark.src = icons("./Logo.png");

            ctx.drawImage(l_watermark, canvasWidth - drawW, canvasHeight - drawH, drawW, drawH);

            // Save background / drawing combo as image
            canvasToBlob(canvas, "image/png")
                .then((blob: Blob) => {
                    onSave(this.state.saveImage);
                    const url = fileToUrl(blob);

                    // Update rendered "download" image
                    this.setState({
                        imageDownloadUrl: url,
                    });

                    onDone(url);

                    // Reset canvas to just the drawing
                    this.resetCanvas();
                    ctx.drawImage(canvasImage, 0, 0);
                })
                .catch((error) => console.error("in ReactPainter handleSave", error));
        };

        backgroundImage.addEventListener("load", loadFunction);
    };

    handleSetColor = (color: string) => {
        this.setState({
            color,
        });
    };

    handleSetLineWidth = (lineWidth: number) => {
        this.setState({
            lineWidth,
        });
    };

    handleSetLineJoin = (type: "round" | "bevel" | "miter") => {
        this.setState({
            lineJoin: type,
        });
    };

    handleSetLineCap = (type: "round" | "butt" | "square") => {
        this.setState({
            lineCap: type,
        });
    };

    getCanvasProps = (props: PropsGetterInput = {}): PropsGetterResult => {
        const { onMouseDown, onTouchStart, onMouseMove, onTouchMove, onMouseUp, onTouchEnd, style, ref, ...restProps } = props;
        return {
            onMouseDown: composeFn(onMouseDown, this.handleMouseDown),
            onMouseMove: composeFn(onMouseMove, this.handleMouseMove),
            onMouseUp: composeFn(onMouseUp, this.handleMouseUp),
            onTouchEnd: composeFn(onTouchEnd, this.handleMouseUp),
            onTouchMove: composeFn(onTouchMove, this.handleMouseMove),
            onTouchStart: composeFn(onTouchStart, this.handleMouseDown),
            ref: composeFn(ref, (canvasRef: HTMLCanvasElement) => {
                if (!!canvasRef && canvasRef !== this.canvasRef) {
                    this.canvasRef = canvasRef;
                    this.ctx = this.canvasRef.getContext("2d");
                    this.init();
                }
            }),
            style: {
                height: this.state.canvasHeight,
                width: this.state.canvasWidth,
                ...style,
            },
            ...restProps,
        };
    };

    init = () => {
        const { width, height } = this.props;
        setUpForCanvas();

        this.initializeCanvas(width, height);

        // Load saved image
        if (this.props.state.logoDrawing != "") {
            this.loadStateImage(this.props.state.logoDrawing);
        }
    };

    // componentDidMount() {
    //     this.init();
    // }

    componentWillUnmount() {
        // Save drawing
        const canvas = this.canvasRef;

        // convert canvas to image
        const canvasImageURL = canvas.toDataURL("image/png");
        const canvasImage = new Image();
        canvasImage.src = canvasImageURL;

        const completedActivities = new Map<string, boolean>(this.props.state.completedActivities);
        if (GetNextIncompleteActivityIndex(completedActivities) != 1) {
            this.saveDrawing(canvasImageURL);
        }

        cleanUpCanvas();
        revokeUrl(this.state.imageDownloadUrl);
    }

    // UI interactions
    sizeClicked = (size: number) => {
        this.handleSetLineWidth(size);
    };

    colorClicked = (color: string) => {
        this.setState({
            color: color,
            erasing: false,
        });
    };

    backgroundClicked = (background: string) => {
        this.props.actions.setLogoBackground(background);
        this.setState({
            background: background,
        });
    };

    setTool = (tool: string) => {
        this.setState({
            tool: tool,
            erasing: false,
        });
    };

    toggleErasing = () => {
        if (this.state.erasing) {
            this.setState({
                erasing: false,
            });
        } else {
            this.setState({
                erasing: true,
            });
        }
    };

    onResetPressed = () => {
        this.showPopup(true);
    };

    CancelReset = () => {
        this.showPopup(false);
    };

    resetCanvas = () => {
        const { width, height } = this.props;
        this.initializeCanvas(width, height);
        this.showPopup(false);
    };

    // Shows the popup
    showPopup = (show: boolean) => {
        this.setState({ showPopup: show });
    };

    render() {
        const { render } = this.props;
        const { imageCanDownload, imageDownloadUrl } = this.state;
        const canvasNode = <canvas {...this.getCanvasProps()} />;
        const currentActivity = this.props.currentActivity ? this.props.currentActivity : ActivityCodes.painter;
        const isComplete = new Map<string, boolean>(this.props.state.completedActivities).get(currentActivity);

        // Brush Sizes
        const smallSize = 5;
        const mediumSize = 10;
        const largeSize = 15;

        // Colors
        const colors = [
            `#dba277`,
            `#7c326f`,
            `#bf341f`, //aqua
            `#4d9a4a`, //blue
            `#234fba`, //purple
            `#d3824a`, //pink
            `#f675dc`, //red
            `#ebc439`, //orange
            `#217c1c`, //yellow
            `#102689`, //light green
            `#683c39`, //dark green
            `#f0f0f0`,
            `#db6631`,
            `#1cc1bd`,
            `#242424`,
        ];

        // Backgrounds
        const backgroundLocation = "../components/Activities/Drawing/Backgrounds/";

        const getClassName = (e: string, value: number) => {
            switch (e) {
                case "eraser":
                    if (this.state.erasing) {
                        return "active";
                    }
                    return "";
                case "smallsize":
                    if (this.state.tool === "brush") {
                        return "active";
                    }
                    return "";
                case "mediumsize":
                    if (this.state.tool === "stamp" && this.state.stamp === "star") {
                        return "active";
                    }
                    return "";
                case "largesize":
                    if (this.state.tool === "stamp" && this.state.stamp === "triangle") {
                        return "active";
                    }
                    return "";

                case "color":
                    if (this.state.color === colors[value] && !this.state.erasing) {
                        return "active";
                    }
                    return "";

                case "background":
                    if (this.state.background === backgrounds[value]) {
                        return "active";
                    }
                    return "";

                case "image":
                    return " active";
            }

            return "";
        };

        const DownloadImage = () => {
            this.handleSave((sUrl: string) => {
                //Creating new link node.
                const link = document.createElement("a");
                link.href = sUrl;

                if (link.download !== undefined) {
                    //Set HTML5 download attribute. This will prevent file from opening if supported.
                    const fileName = "Logo.png";
                    link.download = fileName;
                }

                //Dispatching click event.
                if (document.createEvent) {
                    const e = document.createEvent("MouseEvents");
                    e.initEvent("click", true, true);
                    link.dispatchEvent(e);
                    return;
                }

                // Force file download (whether supported by server).
                if (!sUrl.includes("?")) {
                    sUrl += "?download";
                }

                window.open(sUrl, "_self");
            });
        };

        const NextActivity = () => {
            if (this.props.selectActivity) {
                this.props.selectActivity(GetNextActivityIndex(currentActivity));
            }
        };

        // The layout of the canvas options
        const buttonLayout = (
            <div className={"painterButtons"}>
                <div className={"buttonRow"}>
                    <div className={"sizes"}>
                        <div onClick={() => this.setTool("brush")} className={"sizeOption small " + getClassName("smallsize")} />
                        <div
                            onClick={() => {
                                this.setTool("stamp");
                                this.setState({ stamp: "star" });
                            }}
                            className={"sizeOption medium " + getClassName("mediumsize")}
                        />
                        <div
                            onClick={() => {
                                this.setTool("stamp");
                                this.setState({ stamp: "triangle" });
                            }}
                            className={"sizeOption large " + getClassName("largesize")}
                        />
                    </div>
                </div>
                <div className={"buttonRow mobile-buttons"}>
                    <div className={"colours"}>
                        <div onClick={() => this.colorClicked(colors[2])} style={{ backgroundColor: colors[2] }} className={"colorOption " + getClassName("color", 2)} />
                        <div onClick={() => this.colorClicked(colors[3])} style={{ backgroundColor: colors[3] }} className={"colorOption " + getClassName("color", 3)} />
                        <div onClick={() => this.colorClicked(colors[4])} style={{ backgroundColor: colors[4] }} className={"colorOption " + getClassName("color", 4)} />

                        <div onClick={() => this.colorClicked(colors[6])} style={{ backgroundColor: colors[6] }} className={"colorOption " + getClassName("color", 6)} />
                        <div onClick={() => this.colorClicked(colors[7])} style={{ backgroundColor: colors[7] }} className={"colorOption " + getClassName("color", 7)} />

                        <div onClick={() => this.colorClicked(colors[14])} style={{ backgroundColor: colors[14] }} className={"colorOption " + getClassName("color", 14)} />
                        <div onClick={() => this.setState({ erasing: true })} className={"colorOption erase " + getClassName("eraser")}>
                            <div className={"eraser-image"}>X</div>
                        </div>
                    </div>
                    <div className={"buttonRow"}>
                        <div className={"backgrounds"}>
                            <div onClick={() => this.backgroundClicked(backgrounds[0])} style={{ backgroundImage: "url(" + require("./Backgrounds/" + backgrounds[0]) + ")" }} className={"backgroundOption " + getClassName("background", 0)} />
                            <div onClick={() => this.backgroundClicked(backgrounds[1])} style={{ backgroundImage: "url(" + require("./Backgrounds/" + backgrounds[1]) + ")" }} className={"backgroundOption " + getClassName("background", 1)} />
                            <div onClick={() => this.backgroundClicked(backgrounds[2])} style={{ backgroundImage: "url(" + require("./Backgrounds/" + backgrounds[2]) + ")" }} className={"backgroundOption " + getClassName("background", 2)} />
                            <div onClick={() => this.backgroundClicked(backgrounds[3])} style={{ backgroundImage: "url(" + require("./Backgrounds/" + backgrounds[3]) + ")" }} className={"backgroundOption " + getClassName("background", 3)} />
                        </div>
                    </div>
                </div>
                <div className="painter-finish-buttons">
                    <div className={"buttonRow complete-buttons"}>
                        <div onClick={DownloadImage}>
                            <div className={"downloadLink" + getClassName("image")}>Download<div className="download-icon image"/></div>
                        </div>
                        {isComplete ? <div className={"continue image"} onClick={NextActivity} /> : null}
                        <div onClick={this.onResetPressed} title="Restart Activity" className={"reset image"} />
                    </div>
                </div>
            </div>
        );

        const EnablePainter = () => {
            this.setState({
                ...this.state,
                showIntro: false,
            });

            this.init();
        };

        const Intro = () => {
            return (
                <div className={"activity-intro painter-intro"}>
                    <div className={"intro-text painter-intro-text"}>
                        <div className="start-text step">Step 4:</div>
                        <div className="start-text title">Create your logo</div>
                        <p>Draw your superhero logo in the box</p>
                    </div>
                    <div className={"start-button intro-start"} onClick={EnablePainter}>
                        Start
                    </div>
                </div>
            );
        };

        return typeof render === "function" ? (
            <>
                {this.state.showIntro && !isComplete ? (
                    Intro()
                ) : (
                    <div>
                        {render({
                            canvas: canvasNode,
                            getCanvasProps: this.getCanvasProps,
                            imageCanDownload,
                            imageDownloadUrl,
                            setColor: this.handleSetColor,
                            setLineCap: this.handleSetLineCap,
                            setLineJoin: this.handleSetLineJoin,
                            setLineWidth: this.handleSetLineWidth,
                            triggerSave: this.handleSave,
                        })}
                        {buttonLayout}
                    </div>
                )}
            </>
        ) : (
            <>
                {this.state.showIntro && !isComplete ? (
                    Intro()
                ) : (
                    <div className={"reactPainter"}>
                        <ConfirmationPopup onYes={this.resetCanvas} onNo={this.CancelReset} show={!!this.state.showPopup}>
                            Are you <strong>sure</strong> you would like to erase your current design?
                        </ConfirmationPopup>
                        <div className="activity-header">My Logo is:</div>
                        <div className={"painterCanvas"}>
                            <div className={"painterForeground"} style={{ backgroundImage: "url(" + require("./Backgrounds/" + this.state.background) + ")" }} />
                            {canvasNode}
                        </div>
                        {buttonLayout}
                    </div>
                )}
            </>
        );
    }
}

export const ReactPainter = withState(withActions(ReactPainterDecorated));
