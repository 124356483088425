import React, { ReactElement } from "react";
import { SuperNameGenerator } from "../components/Activities/Superhero Name Generator/NameGenerator";
import { Checklist } from "../components/Activities/Checklist/Checklist";
import { QuizPopupContent } from "../components/Activities/Quizzes/QuizPopupContent";
import { QuizData } from "./QuizData";
import { CostumePicker } from "../components/Activities/Hero Costume/CostumePicker";
import { ReactPainter } from "../components/Activities/Drawing";
import { Minesweeper } from "../components/Activities/Minesweeper";
import { Outro } from "../components/Activities/Outro/Outro";
import { Intro } from "../components/Activities/Intro/Intro";
import { ActivityCodes } from "./ActivityCodes";
import { FinderGame } from "../components/Activities/Finder Game";

interface IActivityInfo {
    titleText: string;
    element: ReactElement;
}

//This map should contain a code and parent component for each different activity.
//Where the key is the activity code.
export const ActivityInfo = new Map<string, IActivityInfo>([
    [
        ActivityCodes.intro,
        {
            titleText: "HOW TO HERO!",
            element: <Intro />,
        },
    ],
    [
        ActivityCodes.nameGen,
        {
            titleText: "Find your super name!",
            element: <SuperNameGenerator />,
        },
    ],
    [
        ActivityCodes.quiz,
        {
            titleText: "Find your super power!",
            element: (
                <QuizPopupContent
                    entity={QuizData}
                    showPopup={() => {
                        return;
                    }}
                    displayResultInline={true}
                />
            ),
        },
    ],
    [
        ActivityCodes.costume,
        {
            titleText: "Create your costume!",
            element: <CostumePicker />,
        },
    ],
    [
        ActivityCodes.painter,
        {
            titleText: "Draw your logo!",
            element: <ReactPainter />,
        },
    ],
    [
        ActivityCodes.checklist,
        {
            titleText: "Track your tasks!",
            element: <Checklist />,
        },
    ],
    [
        ActivityCodes.finder,
        {
            titleText: "Find the Gems!",
            element: <FinderGame height={10} width={10} grid={[]} />, //Currently these inputs actually do nothing.
        },
    ],
    [
        ActivityCodes.outro,
        {
            titleText: "You did it!",
            element: <Outro />,
        },
    ],
]);

export const GetNextActivityIndex = (activityCode: string): number => {
    const keys = [...ActivityInfo.keys()];
    const index = keys.findIndex((entry) => {
        return entry === activityCode;
    });

    if (index > -1 && index + 1 < keys.length) {
        return index + 1;
    } else {
        return keys.length - 1; //If it doesn't exist, or there is no next, return the last key index.
    }
};

export const GetNextIncompleteActivityIndex = (completedActivites: Map<string, boolean>): number => {
    let index = 0; //Todo: Find a better way of handling this.
    for (const key of ActivityInfo.keys()) {
        if (!completedActivites.get(key)) {
            return index;
        }
        ++index;
    }

    //All activities are complete.
    return -1;
};
