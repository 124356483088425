import { actionReducer } from "../actionReducer";
import { isUpdateTaskAction } from "../actionTests/isUpdateActionTask";
import { initialState } from "../initialState";
import { analytics, analyticsCategories } from "../../consts/Analytics";

export const updateTaskReducer = actionReducer(
    initialState,
    (previousState = initialState, action) => {
        analytics.track("updatedChecklistTask", {
            category: analyticsCategories.checklist,
            label: action.task,
            complete: action.complete,
        });

        const checklist = [...previousState.checklist];
        checklist[action.taskID] = { task: action.task, complete: action.complete };

        return {
            ...previousState,
            checklist,
        };
    },
    isUpdateTaskAction,
);
