import React, { PropsWithChildren } from "react";
import { withActions, WithActions } from "../../../hocs/withActions";
import { DebounceInput } from "react-debounce-input";

interface IProps {
    taskID: number;
    task: string;
    complete?: boolean;
    customChecklist: boolean;
}

const ChecklistEntryDecorated = (props: PropsWithChildren<IProps & WithActions>) => {
    const { taskID, task, complete, customChecklist } = props;
    const { removeTask, updateTask } = props.actions.tasks;

    const ToggleComplete = () => {
        updateTask(taskID, task, !complete);
    };

    const DeleteTask = () => {
        removeTask(taskID);
    };

    const OnInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateTask(taskID, event.target.value, !!complete); //!!complete allows handles of the case where complete is undefined.
    };

    return (
        <div className={"checklist-entry"}>
            <div className={"checkbox " + (complete ? "checkbox-complete" : "checkbox-incomplete")} onClick={ToggleComplete} />
            <DebounceInput value={task} readOnly={!customChecklist} onChange={OnInputChanged} debounceTimeout={1000} className={"task-name-input " + (customChecklist ? "can-edit" : "read-only")} />
            {customChecklist ? (
                <div className={"task-delete-button"} onClick={DeleteTask}>
                    X
                </div>
            ) : null}
        </div>
    );
};

export const ChecklistEntry = withActions(ChecklistEntryDecorated);
