import React, { PropsWithChildren } from "react";
import { WithActions, withActions } from "../../../hocs/withActions";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { StandardImageBox } from "../../Popups/StandardImageBox";

interface IProps {
    selectorID: number;
    partIndex: number;
    parts: string[];
    canEdit: boolean;
}

const PartSelectorDecorated = (props: PropsWithChildren<IProps & WithActions>) => {
    const { partIndex, canEdit, selectorID, parts } = props;
    const { setCostumeSelection } = props.actions;

    const OnSelectionChanged = (index: number) => {
        if (canEdit) {
            setCostumeSelection(selectorID, index);
        }
    };

    const carouselProps = {
        showArrows: canEdit,
        showStatus: false,
        showIndicators: false,
        infiniteLoop: true,
        showThumbs: false,
        useKeyboardArrows: false,
        autoPlay: false,
        stopOnHover: false,
        swipeable: canEdit,
        dynamicHeight: false,
        emulateTouch: canEdit,
        selectedItem: partIndex,
        onChange: OnSelectionChanged,
    };

    return (
        <div className={`part-selector-wrapper part-selector-${selectorID}`}>
            <Carousel {...carouselProps}>
                {parts.map((entry, index) => {
                    return <StandardImageBox key={index} file={entry} backgroundSize={"contain"} classNames={["costume-part"]} />;
                })}
            </Carousel>
        </div>
    );
};

export const PartSelector = withActions(PartSelectorDecorated);
