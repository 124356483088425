import React, { PropsWithChildren, useState } from "react";
import { WithActions, withActions } from "../hocs/withActions";
import { ConfirmationPopup } from "./ConfirmationPopup";

interface IProps {
    selectActivity: (id: number) => void
}

const FooterDecorated = (props: PropsWithChildren<IProps & WithActions>) => {
    const { selectActivity } = props;
    const { resetAll } = props.actions;
    const [confirmReset, setConfirmReset] = useState<boolean>(false);

    const onResetClicked = () => {
        setConfirmReset(true);
    };

    const CancelReset = () => {
        setConfirmReset(false);
    };

    const ResetBook = () => {
        resetAll();
        selectActivity(0);
        setConfirmReset(false);
    };

    return (
        <div className={"footer"}>
            <div>
                <div className="tfsc"/>
                <h3>Follow</h3>
                <ul>
                    <li><a href="https://www.facebook.com/talesfromsanctuarycity/" target="_blank"><div className="facebook social"/></a></li>
                    <li><a href="https://www.instagram.com/talesfromsanctuarycity/" target="_blank"><div className="instagram social"/></a></li>
                </ul>
                <p><strong>#TFSC #CombatWombat #VisitSanctuaryCity</strong></p>
                <ul className="partners">
                    <li><a href="https://www.screenaustralia.gov.au/" target="_blank"><div className="screen-australia partner"/></a></li>
                    <li><a href="https://screenqueensland.com.au/" target="_blank"><div className="screen-queensland partner"/></a></li>
                    <li><a href="https://www.likeaphoton.com/" target="_blank"><div className="lapc partner"/></a></li>
                    <li><a href="https://www.ponyuppicturestudios.com/" target="_blank"><div className="pony-up partner"/></a></li>
                    <li><a href="https://www.universalpictures.com.au/" target="_blank"><div className="universal partner"/></a></li>
                    <li><a href="https://www.odinseyeent.com/" target="_blank"><div className="odins-eye partner"/></a></li>
                </ul>
                <p>Copyright © Like A Photon Creative, 2020</p>
            </div>
            <div title="Reset How to Hero Book" className="reset image" onClick={onResetClicked} />
            <ConfirmationPopup onYes={ResetBook} onNo={CancelReset} show={confirmReset}>
                Are you <strong>sure</strong> you want to reset <strong>ALL</strong> your progress?
            </ConfirmationPopup>
        </div>
    );
};

export const Footer = withActions(FooterDecorated);
