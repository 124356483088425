import { PopulatedQuizQuestion } from "../interfaces/quizzes/PopulatedQuizQuestion";
import { PopulatedQuiz } from "../interfaces/quizzes/PopulatedQuiz";
import { LoadedContent } from "../interfaces/quizzes/IContent";
import { IQuizResult } from "../interfaces/quizzes/IQuizResult";
import { IQuizAnswer } from "../interfaces/quizzes/IQuizAnswer";

const EmptyQuestion: PopulatedQuizQuestion = {
    id: -1,
    label: "This is a question.",
    quizId: 0,
    questionOrder: 0,
    thumbnailFile: undefined,
    quiz: {
        id: 0,
        label: "",
        quizType: 0,
    },
    quizAnswers: [],
};

const EmptyAnswer: IQuizAnswer = {
    id: -1,
    label: "This is an answer.",
    thumbnailFile: undefined,
    quizQuestionId: 0,
    answerOrder: 0,
    score: undefined,
};

const EmptyResult: IQuizResult = {
    id: -1,
    label: "This is a result.",
    thumbnailFile: undefined,
    quizId: 0,
    scoreThreshold: 0,
    detail: "Here are some result details.",
};

export const QuizData: LoadedContent<PopulatedQuiz> = {
    label: "Temp",
    linkedContent: {
        id: 0,
        label: "The Quiz",
        quizType: 0,
        quizQuestions: [
            {
                ...EmptyQuestion,
                label: "Which do you prefer?",
                thumbnailFile: "Sprite.png",
                quizAnswers: [
                    {
                        ...EmptyAnswer,
                        label: "Air",
                        score: '{"0":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1422132940975-a71d3ffef351?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Space",
                        score: '{"1":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1464802686167-b939a6910659?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2666&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Fire",
                        score: '{"2":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1517594422361-5eeb8ae275a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Light",
                        score: '{"3":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1482164565953-04b62dcac1cd?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Water",
                        score: '{"4":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1484291470158-b8f8d608850d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Spirit",
                        score: '{"5":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1473147654241-a26ffc2146bb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1993&q=80",
                    },
                ],
            },
            {
                ...EmptyQuestion,
                label: "Choose a place to live!",
                id: 1,
                questionOrder: 1,
                thumbnailFile: "Void.jpg",
                quizAnswers: [
                    {
                        ...EmptyAnswer,
                        label: "NYC",
                        score: '{"2":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1496442226666-8d4d0e62e6e9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Brisbane",
                        score: '{"5":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1566734904496-9309bb1798ae?ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "London",
                        score: '{"1":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1505761671935-60b3a7427bad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Tokyo",
                        score: '{"4":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1551641506-ee5bf4cb45f1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1662&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Madrid",
                        score: '{"0":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1543783207-ec64e4d95325?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Moscow",
                        score: '{"3":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1547448415-e9f5b28e570d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
                    },
                ],
            },
            {
                ...EmptyQuestion,
                label: "Pick an animal you love!",
                id: 1,
                questionOrder: 1,
                thumbnailFile: "Void.jpg",
                quizAnswers: [
                    {
                        ...EmptyAnswer,
                        label: "Frog",
                        score: '{"1":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1502780402662-acc01c084a25?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1680&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Cat",
                        score: '{"3":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1514888286974-6c03e2ca1dba?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1627&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Dog",
                        score: '{"2":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1561037404-61cd46aa615b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Horse",
                        score: '{"4":1}',
                        thumbnailFile: "https://images.unsplash.com/flagged/photo-1557296126-ae91316e5746?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Dolphin",
                        score: '{"5":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1539131258078-89aaa90dd845?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Butterfly",
                        score: '{"0":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1557912407-eb2900cf49e8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                ],
            },
            {
                ...EmptyQuestion,
                label: "Choose a chore to do!",
                id: 1,
                questionOrder: 1,
                thumbnailFile: "Void.jpg",
                quizAnswers: [
                    {
                        ...EmptyAnswer,
                        label: "Cleaning",
                        score: '{"5":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Washing",
                        score: '{"2":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1517677208171-0bc6725a3e60?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Vacuuming",
                        score: '{"4":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1527515637462-cff94eecc1ac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Cooking",
                        score: '{"1":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1514986888952-8cd320577b68?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1655&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Walking the dog",
                        score: '{"0":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1592885473627-b483583627e3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Gardening",
                        score: '{"3":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1555955207-b96159c16808?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                ],
            },
            {
                ...EmptyQuestion,
                label: "Choose your accessory!",
                id: 1,
                questionOrder: 1,
                thumbnailFile: "Void.jpg",
                quizAnswers: [
                    {
                        ...EmptyAnswer,
                        label: "Cape",
                        score: '{"0":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1485203097322-646784956f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Boots",
                        score: '{"4":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1520640023173-50a135e35804?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Helmet",
                        score: '{"5":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1494030575520-dd03dd6aeb04?ixlib=rb-1.2.1&auto=format&fit=crop&w=2035&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Mask",
                        score: '{"3":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1558085324-2f298b28c714?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Shield",
                        score: '{"1":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1530633866995-0ad6c385894d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2015&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Belt",
                        score: '{"2":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1589579116155-d251612794c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                ],
            },
            {
                ...EmptyQuestion,
                label: "Pick a type of movie!",
                id: 1,
                questionOrder: 1,
                thumbnailFile: "Void.jpg",
                quizAnswers: [
                    {
                        ...EmptyAnswer,
                        label: "Science Fiction",
                        score: '{"5":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1578374173703-71809a1757b1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Comedy",
                        score: '{"4":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1528809217021-151305b50e55?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Action",
                        score: '{"2":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1520371764250-8213f40bc3ed?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Romance",
                        score: '{"0":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1474552226712-ac0f0961a954?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1651&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Fantasy",
                        score: '{"1":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1514539079130-25950c84af65?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1649&q=80",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Mystery",
                        score: '{"3":1}',
                        thumbnailFile: "https://images.unsplash.com/photo-1446511437394-36cdff3ae1b3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
                    },
                ],
            },
        ],
        quizResults: [
            {
                ...EmptyResult,
                id: 0,
                label: "Flight",
                detail: "You can zoom gracefully through the air like a sugar glider!",
                thumbnailFile: "https://images.unsplash.com/photo-1436128003323-97dab5d267a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
            },
            {
                ...EmptyResult,
                id: 1,
                label: "Teleportation",
                detail: "You can travel great distances in the blink of an eye!",
                thumbnailFile: "https://images.unsplash.com/photo-1536405528985-0ab8ba47f25e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
            },
            {
                ...EmptyResult,
                id: 2,
                label: "Super Strength",
                detail: "Like a wombat, you can lift immense weights and burrow through solid walls!",
                thumbnailFile: "https://images.unsplash.com/photo-1471286174890-9c112ffca5b4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
            },
            {
                ...EmptyResult,
                id: 3,
                label: "Super Genius",
                detail: "Your great cunning lets you outsmart the bad guys effortlessly.",
                thumbnailFile: "https://images.unsplash.com/photo-1507413245164-6160d8298b31?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
            },
            {
                ...EmptyResult,
                id: 4,
                label: "Super Speed",
                detail: "Like an emu, you can run at tremendous speeds!",
                thumbnailFile: "https://images.unsplash.com/photo-1498056523130-b76aa40137b0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
            },
            {
                ...EmptyResult,
                id: 5,
                label: "Mind Reader",
                detail: "You are able to hear people's thoughts and always know what to get everyone for their birthday.",
                thumbnailFile: "https://images.unsplash.com/photo-1559757148-5c350d0d3c56?ixlib=rb-1.2.1&auto=format&fit=crop&w=6000&q=80",
            },
        ],
    },
};

/*
{
                //The one question we're not using.
                ...EmptyQuestion,
                label: "Choose a catchphrase",
                id: 1,
                questionOrder: 1,
                thumbnailFile: "Void.jpg",
                quizAnswers: [
                    {
                        ...EmptyAnswer,
                        label: "I'm outta here",
                        score: '{"1":1}',
                        thumbnailFile: "Rock",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Try and keep up",
                        score: '{"4":1}',
                        thumbnailFile: "Crab",
                    },
                    {
                        ...EmptyAnswer,
                        label: "Meet pain",
                        score: '{"2":1}',
                        thumbnailFile: "Rock",
                    },
                    {
                        ...EmptyAnswer,
                        label: "I'm always two steps ahead",
                        score: '{"3":1}',
                        thumbnailFile: "Crab",
                    },
                    {
                        ...EmptyAnswer,
                        label: "I can hear you...",
                        score: '{"5":1}',
                        thumbnailFile: "Rock",
                    },
                    {
                        ...EmptyAnswer,
                        label: "I just landed",
                        score: '{"0":1}',
                        thumbnailFile: "Crab",
                    },
                ],
            },
 */
