import { actionReducer } from "../actionReducer";
import { isSetActivityCompletionAction } from "../actionTests/isSetActivityCompletionAction";
import { initialState } from "../initialState";
import { analytics } from "../../consts/Analytics";
import { ActivityCodes } from "../../consts/ActivityCodes";

export const setActivityCompletionReducer = actionReducer(
    initialState,
    (previousState = initialState, action) => {
        const activitiesMap = new Map<string, boolean>(previousState.completedActivities);

        //Only fire the complete event if the state is actually changing.
        if (activitiesMap.get(action.activityCode) != action.complete) {
            analytics.track("setActivityComplete", {
                category: action.activityCode,
                label: action.activityCode,
                value: action.complete,
            });

            //Todo: Review this implementation.
            if (action.activityCode == ActivityCodes.costume && action.complete) {
                let costumeString = "";
                previousState.costumeSelections.map((entry) => {
                    costumeString += entry.toString() + " ";
                });

                analytics.track("selectedCostume", {
                    category: action.activityCode,
                    label: "mask/suit/boots",
                    value: costumeString,
                });
            }
        }

        activitiesMap.set(action.activityCode, action.complete);
        const completedActivities: [string, boolean][] = [...activitiesMap.entries()];

        return {
            ...previousState,
            completedActivities,
        };
    },
    isSetActivityCompletionAction,
);
