import { State } from "../../common/types/State";
import { ActivityCodes } from "../consts/ActivityCodes";

export const initialState: State = {
    counter: 0,
    superheroName: "???",
    checklist: [],
    customChecklist: false,
    costumeSelections: [0, 0, 0],
    completedActivities: [
        [ActivityCodes.intro, true],
        [ActivityCodes.nameGen, false],
        [ActivityCodes.checklist, false],
        [ActivityCodes.costume, false],
        [ActivityCodes.finder, false],
        [ActivityCodes.quiz, false],
        [ActivityCodes.painter, false],
        [ActivityCodes.outro, false],
    ],
    quizResult: -1,
    logoDrawing: "",
    logoBackground: "background-1.png",
    rescueRecord: 0,
};
