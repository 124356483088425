import React, { PropsWithChildren } from "react";
import { ActivityInfo, GetNextIncompleteActivityIndex } from "../../consts/ActivityInfo";
import { WithState, withState } from "../../hocs/withState";

interface IProps {
    setSelection: (id: number) => void;
    currentSelection: number;
}

const ActivitySelectorDecorated = (props: PropsWithChildren<IProps & WithState>) => {
    const currentSelection = props.currentSelection;
    const completedActivities = new Map<string, boolean>(props.state.completedActivities);
    const nextActivity = GetNextIncompleteActivityIndex(completedActivities);

    return (
        <div className={"activity-selector-parent"}>
            <div className="progress-bar" />
            <div className="badges">
                {[...ActivityInfo.keys()].map((entry, index) => {
                    let classNames = `button activity-button ${entry}-activity-button`;
                    classNames += index == currentSelection ? " selected" : "";
                    classNames += completedActivities.get(entry) ? " complete" : " incomplete";
                    classNames += index > nextActivity && nextActivity > -1 ? " locked" : "";

                    return <div title={entry} key={index} className={classNames} onClick={() => props.setSelection(index)} />;
                })}
            </div>
        </div>
    );
};

export const ActivitySelector = withState(ActivitySelectorDecorated);
