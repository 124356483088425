import { Router } from "@reach/router";
import React, { PropsWithChildren, useEffect } from "react";
import { WithAnalytics, withAnalytics } from "../hocs/withAnalytics";
import { SEO } from "./SEO";
import { Store } from "./Store";

interface IProps {}

const LayoutDecorated = (props: PropsWithChildren<IProps & WithAnalytics>) => {
    const { children, analytics } = props;

    useEffect(() => {
        analytics.page();
    }, []);

    return (
        <>
            <SEO title={"How To Hero"} />
            <Store>
                <Router>{children}</Router>
            </Store>
        </>
    );
};

export const Layout = withAnalytics(LayoutDecorated);
