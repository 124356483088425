export const CostumeParts = [
    //Head Parts
    [
        "CostumeParts/Heads/head1.png",
        "CostumeParts/Heads/head2.png",
        "CostumeParts/Heads/head3.png",
        "CostumeParts/Heads/head4.png",
        "CostumeParts/Heads/head5.png",
        "CostumeParts/Heads/head6.png",
        "CostumeParts/Heads/head7.png",
        "CostumeParts/Heads/head8.png",
        "CostumeParts/Heads/head9.png"
    ],
    //Cape Parts
    [
        "CostumeParts/Capes/cape1.png",
        "CostumeParts/Capes/cape2.png",
        "CostumeParts/Capes/cape3.png",
        "CostumeParts/Capes/cape4.png",
        "CostumeParts/Capes/cape5.png"
    ],
    //Suit Parts
    [
        "CostumeParts/Suits/suit1.png",
        "CostumeParts/Suits/suit2.png",
        "CostumeParts/Suits/suit3.png",
        "CostumeParts/Suits/suit4.png",
        "CostumeParts/Suits/suit5.png"
    ],
];
