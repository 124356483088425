import { actionReducer } from "../actionReducer";
import { isSetCostumeSelectionAction } from "../actionTests/isSetCostumeSelectionAction";
import { initialState } from "../initialState";
import { analytics, analyticsCategories } from "../../consts/Analytics";

export const setCostumeSelectionReducer = actionReducer(
    initialState,
    (previousState = initialState, action) => {
        let label = action.selectorIndex == 0 ? "mask" : "boots";
        label = action.selectorIndex == 1 ? "suit" : label;
        analytics.track("setCostumePart" + action.selectorIndex, {
            category: analyticsCategories.costume,
            label: label,
            value: action.partIndex,
        });

        const costumeSelections = [...previousState.costumeSelections];
        costumeSelections[action.selectorIndex] = action.partIndex;

        return {
            ...previousState,
            costumeSelections,
        };
    },
    isSetCostumeSelectionAction,
);
