export const ActivityCodes = {
    nameGen: "nameGen",
    checklist: "checklist",
    quiz: "quiz",
    costume: "costume",
    painter: "painter",
    //minesweeper: "minesweeper",
    intro: "intro",
    outro: "outro",
    finder: "finder",
};
