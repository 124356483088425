type CSSProperties = {
    backgroundImage?: string;
    backgroundSize?: string;
};

export const buildOptionalFileBackgroundCSS = (file?: string, backgroundSize = "cover"): CSSProperties => {
    if (file) {
        let imagePath = file;

        if (!imagePath.startsWith("http")) {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            imagePath = require(`../images/${file}`);
        }

        return {
            backgroundImage: `url('${imagePath}')`,
            backgroundSize,
        };
    }

    return {};
};
